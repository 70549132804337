import { Grid, Paper, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
export default function AboutUs() {
  const post = {
    title: 'Welcome to Foliocontrol!',
    description: `Our platform provides a seamless and user-friendly experience, allowing you to effortlessly take control of your diverse portfolio and efficiently handle all those time-consuming business tasks that tend to pile up. With our intuitive interface, you can easily navigate through your investments, assets, and projects, ensuring a clear overview and informed decision-making. Say goodbye to the hassle of managing multiple accounts, sifting through piles of paperwork, and juggling various business errands. Our solution streamlines and automates these processes, saving you valuable time and energy. Whether it's tracking financial performance, monitoring market trends, or handling administrative chores, our platform empowers you to stay on top of your business with ease.`,
    image: 'https://picsum.photos/900/700',
    imageText: 'Colorful image',
  };
  return (
    <Paper
      sx={{
        borderRadius: '25px',
        position: 'relative',
        backgroundColor: 'grey.800',
        color: '#fff',
        mb: 4,
        minHeight: '750px',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundImage: `url(${post.image})`,
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          right: 0,
          left: 0,
          borderRadius: '25px',
          backgroundColor: 'rgba(0,0,0,.3)',
        }}
      />
      <Grid container>
        <Grid item md={6}>
          <Box
            sx={{
              position: 'relative',
              p: { xs: 3, md: 6 },
              pr: { md: 0 },
            }}
          >
            <Typography component='h1' variant='h3' color='inherit' gutterBottom>
              {post.title}
            </Typography>
            <Typography variant='h5' color='inherit' paragraph>
              {post.description}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
}
