import { Typography, Box, TextField, Skeleton, Grid, Container, Paper } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { Save as SaveIcon } from '@mui/icons-material';

import { useEffect, useState } from 'react';
import { useSnackbarContext } from '../../../context/ui/SnackbarProvider';
import usePartnership from '../../../api/partnership';
export default function PartnershipDetails({ partnership }) {
  const { handleSnackbar } = useSnackbarContext();
  const partnershipApi = usePartnership();
  const [changed, setChanged] = useState(false);
  const [oldValue, setOldValue] = useState({});
  const [loadingAddress, setLoadingAddress] = useState(true);
  const [saving, setSaving] = useState(false);

  const [partnershipName, setPartnershipName] = useState('');
  const [vatNumber, setVatNumber] = useState('');
  const [street, setStreet] = useState('');
  const [streetNumber, setstreetNumber] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [city, setCity] = useState('');
  const [country, setCountry] = useState('');
  const [countryCode, setCountryCode] = useState('');

  const setAll = (partnership) => {
    setPartnershipName(partnership.name || '');
    setVatNumber(partnership.vatNumber || '');
    setStreet(partnership.street || '');
    setstreetNumber(partnership.streetNumber || '');
    setZipCode(partnership.zipCode || '');
    setCity(partnership.city || '');
    setCountry(partnership.country || '');
    setCountryCode(partnership.countryCode || '');
  };

  useEffect(() => {
    setAll(partnership);
    setLoadingAddress(false);
    setOldValue(partnership);
  }, [partnership]);

  const handleChange = (setter, original) => (event) => {
    setChanged(true);
    setter(event.target.value);
    if (event.target.value === original) {
      setChanged(false);
    }
  };

  const saveChanges = async () => {
    setSaving(true);
    const changedValues = {
      partnershipID: partnership.partershipID,
      partnershipName,
      street,
      streetNumber,
      city,
      zipCode,
      country,
    };
    try {
      await partnershipApi.updatePartnership(changedValues);
      handleSnackbar({
        content: 'Partnership information updated successfully',
        severity: 'success',
      });
    } catch (e) {
      handleSnackbar({
        content: `Failed to update: ${e.message}`,
        severity: 'error',
      });
    }
    setOldValue(changedValues);
    setChanged(false);
    setSaving(false);
  };

  return (
    <Container maxWidth='lg'>
      <Paper sx={{ marginTop: '20px', padding: '20px', margin: '20px auto', width: '100%', height: '100%' }}>
        <Typography variant='h2'>Partnership information</Typography>

        <Box>
          {loadingAddress ? (
            <Skeleton sx={{ m: 1 }} />
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                {' '}
                <TextField
                  id='partnershipName'
                  value={partnershipName}
                  onChange={handleChange(setPartnershipName, oldValue.partnershipName)}
                  label='Partnership Name'
                  variant='outlined'
                  fullWidth
                  margin='normal'
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                {' '}
                <TextField id='vatNumber' value={vatNumber} onChange={handleChange(setVatNumber, oldValue.vatNumber)} label='Vat Number' variant='outlined' fullWidth margin='normal' />
              </Grid>
            </Grid>
          )}
        </Box>
        <Box mt={2}>
          {loadingAddress ? (
            <Skeleton sx={{ m: 1 }} />
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                {' '}
                <TextField id='street' value={street} onChange={handleChange(setStreet, oldValue.street)} label='Street' variant='outlined' fullWidth margin='normal' />
              </Grid>
              <Grid item xs={12} sm={4}>
                {' '}
                <TextField id='streetNumber' value={streetNumber} onChange={handleChange(setstreetNumber, oldValue.streetNumber)} label='Street Number' variant='outlined' fullWidth margin='normal' />
              </Grid>
              <Grid item xs={12} sm={4}>
                {' '}
                <TextField id='city' value={city} onChange={handleChange(setCity, oldValue.city)} label='City' variant='outlined' fullWidth margin='normal' />
              </Grid>
              <Grid item xs={12} sm={4}>
                {' '}
                <TextField id='zipCode' value={zipCode} onChange={handleChange(setZipCode, oldValue.zipCode)} label='Zip Code' variant='outlined' fullWidth margin='normal' />
              </Grid>
              <Grid item xs={12} sm={4}>
                {' '}
                <TextField id='country' value={country} onChange={handleChange(setCountry, oldValue.country)} label='Country' variant='outlined' fullWidth margin='normal' />
              </Grid>
              <Grid item xs={12} sm={4}>
                {' '}
                <TextField id='countryCode' value={countryCode} onChange={handleChange(setCountryCode, oldValue.countryCode)} label='Country Code' variant='outlined' fullWidth margin='normal' />
              </Grid>
            </Grid>
          )}
        </Box>
        <LoadingButton loading={saving} onClick={saveChanges} disabled={!changed} loadingPosition='start' startIcon={<SaveIcon />} variant='outlined' fullWidth>
          Save changes
        </LoadingButton>
      </Paper>
    </Container>
  );
}
