import React from 'react';
import { Container, Typography, Box, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

export default function PageNotFound() {
  return (
    <Container>
      <Box display='flex' flexDirection='column' alignItems='center' justifyContent='center' minHeight='calc(100vh - 64px)' textAlign='center'>
        <ErrorOutlineIcon sx={{ fontSize: '5rem', color: 'error.main' }} />
        <Typography variant='h4' gutterBottom>
          Page Not Found
        </Typography>
        <Typography variant='body1' color='textSecondary' gutterBottom>
          Oops! The page you're looking for doesn't exist.
        </Typography>
        <Button variant='contained' color='primary' component={Link} to='/'>
          Go to Home
        </Button>
      </Box>
    </Container>
  );
}
