import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Tooltip,
  ListItemIcon,
  ListItemButton,
  MenuItem,
  Menu,
  Typography,
  Avatar,
} from "@mui/material";
import {
  Person as PersonIcon,
  ExitToApp as ExitToAppIcon,
  Settings,
  DomainVerification,
  DomainAdd,
} from "@mui/icons-material";

import { useAuthContext } from "../../context/AuthProvider";
import { Colors } from "../../styles/themes";

export default function UserAccountMenu({ matches }) {
  const { isAuth, logout, currentUser } = useAuthContext();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (isAuth) {
    return (
      <>
        <Tooltip title="Open settings">
          <ListItemButton
            sx={{
              justifyContent: "center",
            }}
            onClick={handleClick}
          >
            <ListItemIcon
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Avatar>{currentUser.name.charAt(0)}</Avatar>
            </ListItemIcon>
            <Typography variant="body2" sx={{ marginLeft: 0.5 }}>
              {currentUser.name}
            </Typography>
          </ListItemButton>
        </Tooltip>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <MenuItem
            component={RouterLink}
            to="/account/userInformation"
            onClick={handleClose}
          >
            <ListItemIcon
              sx={{
                display: "flex",
                color: Colors.secondary,
                ":hover": {
                  color: Colors.bottom,
                },
              }}
            >
              <PersonIcon />
            </ListItemIcon>
            My Account
          </MenuItem>
          <MenuItem
            component={RouterLink}
            to="/account/partnership"
            onClick={handleClose}
          >
            <ListItemIcon
              sx={{
                display: "flex",
                color: Colors.secondary,
                ":hover": {
                  color: Colors.bottom,
                },
              }}
            >
              <DomainAdd />
            </ListItemIcon>
            Partnerships
          </MenuItem>
          <MenuItem
            component={RouterLink}
            to="/account/options"
            onClick={handleClose}
          >
            <ListItemIcon
              sx={{
                display: "flex",
                color: Colors.secondary,
                ":hover": {
                  color: Colors.bottom,
                },
              }}
            >
              <Settings />
            </ListItemIcon>
            Settings
          </MenuItem>

          <MenuItem
            onClick={() => {
              logout();
              handleClose();
            }}
          >
            <ListItemIcon
              sx={{
                display: "flex",

                color: Colors.secondary,
                ":hover": {
                  color: Colors.bottom,
                },
              }}
            >
              <ExitToAppIcon />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
      </>
    );
  }
  return null; // Return nothing if not authenticated
}
