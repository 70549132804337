import {
  Home as HomeIcon,
  Apartment as ApartmentIcon,
  Garage as GarageIcon,
  Store as StoreIcon,
  Storage as StorageIcon,
} from "@mui/icons-material";

export const countryFlags = {
  AL: "🇦🇱", // Albania
  AD: "🇦🇩", // Andorra
  AT: "🇦🇹", // Austria
  BY: "🇧🇾", // Belarus
  BE: "🇧🇪", // Belgium
  BA: "🇧🇦", // Bosnia and Herzegovina
  BG: "🇧🇬", // Bulgaria
  HR: "🇭🇷", // Croatia
  CY: "🇨🇾", // Cyprus
  CZ: "🇨🇿", // Czech Republic
  DK: "🇩🇰", // Denmark
  EE: "🇪🇪", // Estonia
  FI: "🇫🇮", // Finland
  FR: "🇫🇷", // France
  DE: "🇩🇪", // Germany
  GR: "🇬🇷", // Greece
  HU: "🇭🇺", // Hungary
  IS: "🇮🇸", // Iceland
  IE: "🇮🇪", // Ireland
  IT: "🇮🇹", // Italy
  XK: "🇽🇰", // Kosovo
  LV: "🇱🇻", // Latvia
  LI: "🇱🇮", // Liechtenstein
  LT: "🇱🇹", // Lithuania
  LU: "🇱🇺", // Luxembourg
  MT: "🇲🇹", // Malta
  MD: "🇲🇩", // Moldova
  MC: "🇲🇨", // Monaco
  ME: "🇲🇪", // Montenegro
  NL: "🇳🇱", // Netherlands
  MK: "🇲🇰", // North Macedonia
  NO: "🇳🇴", // Norway
  PL: "🇵🇱", // Poland
  PT: "🇵🇹", // Portugal
  RO: "🇷🇴", // Romania
  RU: "🇷🇺", // Russia
  SM: "🇸🇲", // San Marino
  RS: "🇷🇸", // Serbia
  SK: "🇸🇰", // Slovakia
  SI: "🇸🇮", // Slovenia
  ES: "🇪🇸", // Spain
  SE: "🇸🇪", // Sweden
  CH: "🇨🇭", // Switzerland
  UA: "🇺🇦", // Ukraine
  GB: "🇬🇧", // United Kingdom
  VA: "🇻🇦", // Vatican City
};
export const PropertyTypes = [
  { type: "House", icon: <HomeIcon /> },
  { type: "Terraced House", icon: <HomeIcon /> },
  { type: "Semi-detached", icon: <HomeIcon /> },
  { type: "Apartment", icon: <ApartmentIcon /> },
  { type: "Garage", icon: <GarageIcon /> },
  { type: "Store", icon: <StoreIcon /> },
  { type: "Storage", icon: <StorageIcon /> },
];
