import React from 'react';
import { Divider, Drawer, List, ListItemButton, ListItemText } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import { lighten } from 'polished';
import { Link, useNavigate } from 'react-router-dom';
import { useUIContext } from '../../context/ui/UIContext';
import { DrawerCloseButton } from '../../styles/navbar';
import { Colors } from '../../styles/themes';

export default function MobileDrawer() {
  const { drawerOpen, setDrawerOpen } = useUIContext();
  const navigate = useNavigate();
  function HandleHowItWorksClick() {
    navigate('/home');
    setTimeout(() => {
      setDrawerOpen(false);
      const howItWorks = document.getElementById('HowItWorks');
      const currentY = howItWorks.getBoundingClientRect().y;
      const newY = currentY - 200;

      window.scrollTo({
        top: newY,
        behavior: 'smooth',
      });
    }, 30);
  }
  function HandleFeaturesClick() {
    setDrawerOpen(false);
    navigate('/home');
    setTimeout(() => {
      const features = document.getElementById('Features');
      const currentY = features.getBoundingClientRect().y;
      const newY = currentY - 200;

      window.scrollTo({
        top: newY,
        behavior: 'smooth',
      });
    }, 30);
  }
  function HandleAboutClick() {
    setDrawerOpen(false);
    navigate('/home');
    setTimeout(() => {
      const aboutUsPaper = document.getElementById('AboutUsPaper');
      aboutUsPaper.scrollIntoView(
        {
          behavior: 'smooth',
        },
        true,
      );
    }, 30);
  }

  return (
    <>
      {drawerOpen && (
        <DrawerCloseButton onClick={() => setDrawerOpen(false)}>
          <CloseIcon
            sx={{
              fontSize: '2em',
              color: lighten(0.09, Colors.secondary),
            }}
          />
        </DrawerCloseButton>
      )}
      <Drawer
        open={drawerOpen}
        sx={{ backdropFilter: 'blur(3px)' }}
        onKeyDown={() => {
          setDrawerOpen(false);
        }}
        onBackdropClick={() => {
          setDrawerOpen(false);
        }}
      >
        <List>
          <ListItemButton onClick={() => HandleHowItWorksClick()}>
            <ListItemText>How it works</ListItemText>
          </ListItemButton>
          <Divider />
          <ListItemButton>
            <ListItemText onClick={() => HandleFeaturesClick()}>Features</ListItemText>
          </ListItemButton>
          <Divider />
          <ListItemButton>
            <ListItemText onClick={() => HandleAboutClick()}>About us</ListItemText>
          </ListItemButton>
          <Divider />
          <ListItemButton component={Link} to='/assistance'>
            <ListItemText>Assistance</ListItemText>
          </ListItemButton>
          <Divider />
        </List>
      </Drawer>
    </>
  );
}
