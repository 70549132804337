import React, { useEffect, useState } from 'react';
import { Typography, Box, TextField, Skeleton, Grid, Container, Paper, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Save as SaveIcon } from '@mui/icons-material';
import usePremises from '../../../api/premises';

export default function PremisesDetails({ premises }) {
  const { updatePremises } = usePremises();
  const [changed, setChanged] = useState(false);
  const [oldValue, setOldValue] = useState({});
  const [saving, setSaving] = useState(false);

  const [premisesName, setPremisesName] = useState('');
  const [bus, setBus] = useState('/');
  const [rent, setRent] = useState('');
  const [img, setImg] = useState('');
  const [description, setDescription] = useState('');
  const [address, setAddress] = useState('');
  const [isActive, setIsActive] = useState(0);
  const [isRented, setIsRented] = useState(0);
  const [tenant, setTenant] = useState('none');
  const [loadingAddress, setLoadingAddress] = useState(true);

  useEffect(() => {
    setLoadingAddress(true);
    setAll(premises);
    setOldValue(premises);
    setLoadingAddress(false);
  }, [premises]);

  const setAll = (premises) => {
    setPremisesName(premises.premisesName || '');
    setBus(premises.bus || '/');
    setRent(premises.rent || '');
    setImg(premises.img || '');
    setDescription(premises.description || '');
    setAddress(premises.address || '');
    setIsActive(premises.isActive || 0);
    setIsRented(premises.isRented || 0);
    setTenant(premises.tenant || 'none');
  };

  const handleChange = (setter, original) => (event) => {
    setChanged(true);
    setter(event.target.value);
    if (event.target.value === original) {
      setChanged(false);
    }
  };

  const saveChanges = async () => {
    setSaving(true);
    const changedValues = {
      premisesID: premises.premisesID,
      premisesName,
      bus,
      rent,
      img,
      description,
      address,
      isActive,
      isRented,
      tenant,
    };
    await updatePremises(changedValues);
    setOldValue(changedValues);
    setChanged(false);
    setSaving(false);
  };

  return (
    <Container maxWidth='lg'>
      <Paper sx={{ marginTop: '20px', padding: '20px', margin: '20px auto', width: '100%', height: '100%' }}>
        <Typography variant='h5'>Premises Details</Typography>
        <Box>
          {loadingAddress ? (
            <Grid container spacing={2}>
              {/* Skeleton loading content */}
              <Grid item xs={12} sm={4}>
                <Skeleton sx={{ m: 1 }} />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Skeleton sx={{ m: 1 }} />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Skeleton sx={{ m: 1 }} />
              </Grid>
              <Grid item xs={12}>
                <Skeleton sx={{ m: 1 }} />
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <TextField id='premisesName' value={premisesName} onChange={handleChange(setPremisesName, oldValue.premisesName)} label='Premises Name' variant='outlined' fullWidth margin='normal' />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField id='bus' value={bus} onChange={handleChange(setBus, oldValue.bus)} label='Bus' variant='outlined' fullWidth margin='normal' />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField id='rent' value={rent} onChange={handleChange(setRent, oldValue.rent)} label='Rent' variant='outlined' fullWidth margin='normal' />
              </Grid>
              <Grid item xs={12}>
                <TextField id='description' value={description} onChange={handleChange(setDescription, oldValue.description)} label='Description' variant='outlined' fullWidth margin='normal' />
              </Grid>
            </Grid>
          )}
        </Box>
        <Box mt={2}>
          {loadingAddress ? (
            <Grid container spacing={2}>
              {/* Skeleton loading content */}
              <Grid item xs={12} sm={4}>
                <Skeleton sx={{ m: 1 }} />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Skeleton sx={{ m: 1 }} />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Skeleton sx={{ m: 1 }} />
              </Grid>
              <Grid item xs={12}>
                <Skeleton sx={{ m: 1 }} />
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <TextField id='address' value={address} onChange={handleChange(setAddress, oldValue.address)} label='Address' variant='outlined' fullWidth margin='normal' />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth variant='outlined' margin='normal' required>
                  <InputLabel htmlFor='isActive'>Is Active</InputLabel>
                  <Select id='isActive' value={isActive} onChange={handleChange(setIsActive, oldValue.isActive)} label='Is Active' displayEmpty variant='outlined'>
                    <MenuItem value={1}>Yes</MenuItem>
                    <MenuItem value={0}>No</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth variant='outlined' margin='normal' required>
                  <InputLabel htmlFor='isRented'>Is Rented</InputLabel>
                  <Select id='isRented' value={isRented} onChange={handleChange(setIsRented, oldValue.isRented)} label='Is Rented' displayEmpty variant='outlined'>
                    <MenuItem value={1}>Yes</MenuItem>
                    <MenuItem value={0}>No</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField id='tenant' value={tenant} onChange={handleChange(setTenant, oldValue.tenant)} label='Tenant' variant='outlined' fullWidth margin='normal' />
              </Grid>
            </Grid>
          )}
        </Box>
        <LoadingButton loading={saving} onClick={saveChanges} disabled={!changed} loadingPosition='start' startIcon={<SaveIcon />} variant='outlined' fullWidth>
          Save changes
        </LoadingButton>
      </Paper>
    </Container>
  );
}
