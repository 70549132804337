/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Typography,
  Grid,
  Button,
  CircularProgress,
  Skeleton,
  Avatar,
} from "@mui/material";
import { Save as SaveIcon } from "@mui/icons-material";

import useUser from "../../api/user";

import { useSnackbarContext } from "../../context/ui/SnackbarProvider";
import { useAuthContext } from "../../context/AuthProvider";

export default function UserInformation() {
  const { handleSnackbar } = useSnackbarContext();
  const { verify } = useAuthContext();

  const userApi = useUser();
  const [changed, setChanged] = useState(false);
  const [oldValue, setOldValue] = useState({});

  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);

  // User account information fields
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  // Address information fields
  const [street, setStreet] = useState("");
  const [streetNumber, setStreetNumber] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");

  const setAll = (user) => {
    // Set user account information
    setName(user.name || "");
    setEmail(user.email || "");
    setFirstName(user.firstName || "");
    setLastName(user.lastName || "");
    setStreet(user.street || "");
    setStreetNumber(user.streetNumber || "");
    setZipCode(user.zipCode || "");
    setCity(user.city || "");
    setCountry(user.country || "");
  };

  async function getCurrentUser() {
    try {
      setLoading(true);

      const newFormattedUser = await userApi.getFormattedUserWithToken();
      setOldValue(newFormattedUser);
      setAll(newFormattedUser);
      setLoading(false);
    } catch (error) {
      handleSnackbar({
        content: `Failed to fetch user information: ${error.message}`,
        severity: "error",
      });
    }
  }

  const handleChange = (setter, original) => (event) => {
    setChanged(true);
    setter(event.target.value);
    if (event.target.value === original) {
      setChanged(false);
    }
  };

  const saveChanges = async () => {
    setSaving(true);
    const changedValues = {
      name,
      email,
      firstName,
      lastName,
      street,
      streetNumber,
      zipCode,
      city,
      country,
    };
    try {
      await userApi.update(changedValues);
      await verify();
      handleSnackbar({
        content: "User's information updated successfully",
        severity: "success",
      });
    } catch (e) {
      handleSnackbar({
        content: `Failed to update: ${e.message}`,
        severity: "error",
      });
    }
    setOldValue(changedValues);
    setChanged(false);
    setSaving(false);
  };

  useEffect(() => {
    getCurrentUser();
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Typography variant="h4">User account information</Typography>
        <Avatar sx={{ width: 100, height: 100, mt: 2, mb: 2 }}>
          {name.charAt(0)}
        </Avatar>

        {loading ? (
          <>
            {[1, 2, 3, 4, 5].map((item) => (
              <Skeleton key={item} sx={{ m: 1 }} animation="pulse" />
            ))}
          </>
        ) : (
          <Box>
            <TextField
              fullWidth
              id="name"
              value={name}
              onChange={handleChange(setName, oldValue.name)}
              label="Alias"
              variant="outlined"
              margin="normal"
            />
            <TextField
              fullWidth
              id="email"
              value={email}
              onChange={handleChange(setEmail, oldValue.email)}
              label="Email"
              variant="outlined"
              margin="normal"
            />
            <TextField
              fullWidth
              id="firstName"
              value={firstName}
              onChange={handleChange(setFirstName, oldValue.firstName)}
              label="First Name"
              variant="outlined"
              margin="normal"
            />
            <TextField
              fullWidth
              id="lastName"
              value={lastName}
              onChange={handleChange(setLastName, oldValue.lastName)}
              label="Last Name"
              variant="outlined"
              margin="normal"
            />
          </Box>
        )}
        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={saveChanges}
          disabled={!changed || saving}
          startIcon={saving ? <CircularProgress size={20} /> : <SaveIcon />}
          sx={{ mt: 2 }}
        >
          Save Changes
        </Button>
      </Grid>

      <Grid item xs={12} md={6}>
        <Typography variant="h4">User address information</Typography>
        {loading ? (
          <>
            {[1, 2, 3, 4, 5].map((item) => (
              <Skeleton key={item} sx={{ m: 1 }} animation="pulse" />
            ))}
          </>
        ) : (
          <>
            <TextField
              fullWidth
              id="street"
              value={street}
              onChange={handleChange(setStreet, oldValue.street)}
              label="Street"
              variant="outlined"
              margin="normal"
            />
            <TextField
              fullWidth
              id="streetNumber"
              value={streetNumber}
              onChange={handleChange(setStreetNumber, oldValue.streetNumber)}
              label="Number"
              variant="outlined"
              margin="normal"
            />
            <TextField
              fullWidth
              id="zipCode"
              value={zipCode}
              onChange={handleChange(setZipCode, oldValue.zipCode)}
              label="Zipcode"
              variant="outlined"
              margin="normal"
            />
            <TextField
              fullWidth
              id="city"
              value={city}
              onChange={handleChange(setCity, oldValue.city)}
              label="City"
              variant="outlined"
              margin="normal"
            />
            <TextField
              fullWidth
              id="country"
              value={country}
              onChange={handleChange(setCountry, oldValue.country)}
              label="Country"
              variant="outlined"
              margin="normal"
            />
          </>
        )}
      </Grid>
    </Grid>
  );
}
