import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, useMediaQuery } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import usePartnershipConnection from '../../../api/partnership_user';
import theme from '../../../styles/themes';
import { Link as Routerlink } from 'react-router-dom';
export default function PartnershipMembers({ partnership }) {
  const { getMembersByPartnershipID } = usePartnershipConnection();
  const [members, setMembers] = useState([]);

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const getPartnershipsData = useCallback(async () => {
    try {
      const partnershipsData = await getMembersByPartnershipID(partnership.partnershipID);
      console.log(partnershipsData);
      setMembers(partnershipsData);
    } catch (error) {
      console.error(error);
    }
  }, [getMembersByPartnershipID, partnership.partnershipID]);

  useEffect(() => {
    getPartnershipsData();
  }, [getPartnershipsData]);

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: isMobile ? 300 : 650 }} aria-label='partnerships table'>
          <TableHead>
            <TableRow>
              <TableCell>Username</TableCell>
              <TableCell align='right'>Email</TableCell>
              <TableCell align='right'>Address</TableCell>
              <TableCell align='center'>Country</TableCell>
              <TableCell align='center'>Role</TableCell>
              <TableCell align='center'>Options</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {members.map((member) => (
              <TableRow key={member.userID} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component='th' scope='row'>
                  {member.name}
                </TableCell>
                <TableCell align='right'>{member.email}</TableCell>
                <TableCell align='right'>
                  {member.street}, {member.zipCode} {member.city}
                </TableCell>
                <TableCell align='center'>{member.country}</TableCell>
                <TableCell align='center'>{member.role}</TableCell>
                <TableCell align='center'>
                  <Button variant='outlined' LinkComponent={Routerlink} to={`${member.partnershipID}`}>
                    Promote
                  </Button>
                  <Button variant='outlined' LinkComponent={Routerlink} to={`${member.partnershipID}`}>
                    Demote
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
