import React, { useEffect, useState } from "react";
import { Typography, Tabs, Tab, Container, Fade, Box } from "@mui/material";
import {
  Settings as SettingsIcon,
  Person as PersonIcon,
  ExitToApp as ExitToAppIcon,
  Business as BusinessIcon,
} from "@mui/icons-material";
import AccountOptions from "./AccountOptions";
import { useAuthContext } from "../../context/AuthProvider";
import UserInformation from "./UserInformation";
import PartnershipSettings from "./PartnershipSettings";
import { Link as Routerlink, useLocation } from "react-router-dom";
import Header from "../portfolio/foliocontrol/components/Header";
export default function Account() {
  const [activeTab, setActiveTab] = useState(0);
  const { logout } = useAuthContext();

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };
  const { pathname } = useLocation();
  useEffect(() => {
    if (pathname === "/account/userInformation") {
      setActiveTab(0);
    } else if (pathname === "/account/partnership") {
      setActiveTab(1);
    } else if (pathname === "/account/options") {
      setActiveTab(2);
    }
  }, [pathname]);

  return (
    <Box m="0 20px">
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Header title="ACCOUNT" subtitle="Account settings" />
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          sx={{
            marginLeft: "auto",
          }}
        >
          <Tab
            label="User Information"
            icon={<PersonIcon />}
            component={Routerlink}
            to={`/account/userInformation`}
            sx={{
              backgroundColor: activeTab === 0 ? "#e0e0e0" : "inherit",
              "&:hover": { backgroundColor: "#f0f0f0" },
            }}
          />
          <Tab
            label="Partnerships"
            icon={<BusinessIcon />}
            component={Routerlink}
            to={`/account/partnership`}
            sx={{
              backgroundColor: activeTab === 1 ? "#e0e0e0" : "inherit",
              "&:hover": { backgroundColor: "#f0f0f0" },
            }}
          />
          <Tab
            label="Options"
            icon={<SettingsIcon />}
            component={Routerlink}
            to={`/account/options`}
            sx={{
              backgroundColor: activeTab === 2 ? "#e0e0e0" : "inherit",
              "&:hover": { backgroundColor: "#f0f0f0" },
            }}
          />
          <Tab
            label="Log Out"
            icon={<ExitToAppIcon />}
            onClick={() => logout()}
            sx={{
              backgroundColor: activeTab === 3 ? "#e0e0e0" : "inherit",
              "&:hover": { backgroundColor: "#f0f0f0" },
            }}
          />
        </Tabs>
      </Box>
      {/* Content Area */}
      <Box>
        {activeTab === 0 && <UserInformation />}
        {activeTab === 1 && <PartnershipSettings />}
        {activeTab === 2 && <AccountOptions />}
      </Box>
    </Box>
  );
}
