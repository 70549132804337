import { Button, Container } from "@mui/material";
import useUser from "../../api/user";
import { useCallback, useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import FolioTable from "../portfolio/foliocontrol/components/FolioTable";
import { countryFlags } from "../../common";
export default function PartnershipSettings() {
  const { getPartnerships } = useUser();
  const [partnerships, setPartnerships] = useState([]);

  const getPartnershipsData = useCallback(async () => {
    try {
      const partnershipsData = await getPartnerships();
      const partnershipsWithIds = partnershipsData.map((partnership) => ({
        ...partnership,
        id: partnership.partnershipID, // Use userID as the unique id
      }));
      setPartnerships(partnershipsWithIds);
    } catch (error) {
      console.error(error);
    }
  }, [getPartnerships]);

  useEffect(() => {
    getPartnershipsData();
  }, [getPartnershipsData]);

  const columns = [
    { field: "name", headerName: "Partnership Name", flex: 1 },
    { field: "vatNumber", headerName: "VAT Number", flex: 1 },
    {
      field: "address",
      headerName: "Address",
      flex: 1,
      valueGetter: (params) =>
        `${params.row.street}, ${params.row.zipCode} ${params.row.city}`,
    },
    {
      field: "country",
      headerName: "Country",
      flex: 1,
      align: "center",
      renderCell: (params) => countryFlags[params.row.countryCode],
    },
    {
      field: "options",
      headerName: "Options",
      flex: 2,
      align: "center",

      renderCell: (params) => (
        <Container sx={{ display: "flex" }}>
          <Button
            variant="outlined"
            component={RouterLink}
            to={`${params.row.partnershipID}`}
            sx={{ mr: 1 }}
          >
            View partnership
          </Button>
          <Button
            variant="outlined"
            component={RouterLink}
            to={`${params.row.partnershipID}`}
          >
            Delete
          </Button>
        </Container>
      ),
    },
  ];

  return <FolioTable rows={partnerships} columns={columns} />;
}
