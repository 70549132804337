import React, { useState } from 'react';

import { Link } from 'react-router-dom';

// material import
import { IconButton, useMediaQuery } from '@mui/material';
import { Search as SearchIcon, Menu as MenuIcon } from '@mui/icons-material';
import { ClickAwayListener } from '@mui/base';
import { useTheme } from '@mui/material/styles';

// context

import { useUIContext } from '../../context/ui/UIContext';

// custom imports
import { AppbarContainer, AppbarHeader, ListItemNavbar, Search, SearchIconWrapper, StyledInputBase } from '../../styles/navbar';
import Actions from './Actions';
import { Colors } from '../../styles/themes';

function SearchButton({ searchBarOpened, setSearchBarOpened }) {
  const handleChange = (e) => {
    if (e.keyCode === 13) {
    }
  };
  if (searchBarOpened) {
    return (
      <>
        <ClickAwayListener onClickAway={() => setSearchBarOpened(false)}>
          <Search sx={{ margin: { sm: '0 auto', xs: '0 auto' }, minWidth: { xs: '100%', sm: '50%' } }}>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase placeholder='Search…' inputProps={{ 'aria-label': 'Search' }} onKeyDown={handleChange} />
          </Search>
        </ClickAwayListener>
      </>
    );
  }
  return (
    <IconButton
      onClick={() => setSearchBarOpened(true)}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        color: Colors.primary,
      }}
    >
      <SearchIcon />
    </IconButton>
  );
}

export default function NavbarMobile({ matches }) {
  const { setDrawerOpen } = useUIContext();
  const [searchBarOpened, setSearchBarOpened] = useState(false);

  const theme = useTheme();
  const removeOtherIcons = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <AppbarContainer>
      {searchBarOpened && removeOtherIcons ? (
        <></>
      ) : (
        <>
          <IconButton
            sx={{
              color: Colors.primary,
            }}
            onClick={() => setDrawerOpen(true)}
          >
            <MenuIcon />
          </IconButton>
          <ListItemNavbar button component={Link} to='/home'>
            <AppbarHeader textAlign='center' variant='h3'>
              FolioControl
            </AppbarHeader>
          </ListItemNavbar>
        </>
      )}
      <SearchButton searchBarOpened={searchBarOpened} setSearchBarOpened={setSearchBarOpened} />
      <Actions matches={matches} />
    </AppbarContainer>
  );
}
