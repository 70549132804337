import styled from '@emotion/styled';
import { Container, Grid } from '@mui/material';

export const ContainerReveal = styled(Container)(({ theme }) => ({
  position: 'relative',
  transform: 'translateY(150px)',
  opacity: 0,
  transition: '1s all ease',

  '&.active.reveal': {
    transform: 'translateY(0)',
    opacity: 1,
  },
}));

export const GridReveal = styled(Grid)(({ theme }) => ({
  transform: 'translateY(0)',
  opacity: 0,
  transition: '1s all ease',

  '&.active.reveal': {
    transform: 'translateY(0)',
    opacity: 1,
  },
}));
