/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import jwt_decode from "jwt-decode";
import useUser from "../api/user";
import { useSnackbarContext } from "./ui/SnackbarProvider";
import usePartnership from "../api/partnership";

export const AuthContext = createContext(false);
export const useAuthContext = () => useContext(AuthContext);

export default function AuthProvider({ children }) {
  const [isAuth, setAuth] = useState(false);
  const [currentUser, setCurrentUser] = useState({});
  const [activePartnership, setActivePartnership] = useState({}); // Added partnerships state
  const [roleInActivePartnership, setRoleInActivePartnership] = useState(""); // Added partnerships state
  const [partnershipList, setPartnershipList] = useState([]); // Added partnerships state
  const [verifyTrigger, setVerifyTrigger] = useState(true); // Added trigger state

  const { handleSnackbar } = useSnackbarContext();
  const userApi = useUser();
  const { getPartnershipByID } = usePartnership();
  const { getPartnerships } = useUser(); // Added partnerships
  const getCurrentUserWithToken = useCallback(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const decoded = jwt_decode(token);

      const user = {
        name: decoded.name,
        email: decoded.email,
        permission: decoded.permission,
      };
      return user;
    }
    return {}; // Return an empty object if there's no token
  }, []);

  const setCurrentPartnerships = useCallback(
    async (selectedPartnershipID) => {
      let partnership;
      if (selectedPartnershipID === "none") {
        return partnership;
      }
      if (selectedPartnershipID === "update") {
        const allpartnership = await getPartnerships();
        setPartnershipList(allpartnership);
        return partnership;
      }
      if (selectedPartnershipID === "default") {
        const allpartnership = await getPartnerships();
        console.log("Allpart: ", allpartnership);
        setPartnershipList(allpartnership);

        if (allpartnership.length === 0) {
          handleSnackbar({
            content: `Create or join a partnership to get started!`,
            severity: "error",
          });
          partnership = {
            name: "Create or join a partnership to get started!",
            vatNumber: "NOPARTNERSHIPDATA",
          };
          setActivePartnership(partnership);
          localStorage.setItem("partnership", activePartnership.partnershipID);
          return partnership;
        } else {
          partnership = { ...allpartnership[0] };
          setActivePartnership(partnership);
          setRoleInActivePartnership(partnership.role);
          return partnership;
        }
      }
      const allpartnership = await getPartnerships();
      console.log(allpartnership);
      if (selectedPartnershipID) {
        const selectedPartnership = allpartnership.find(
          (p) => p.partnershipID === selectedPartnershipID
        );
        partnership = {
          partnershipID: selectedPartnership.partnershipID,
          name: selectedPartnership.name,
          vatNumber: selectedPartnership.vatNumber,
          street: selectedPartnership.street,
          streetNumber: selectedPartnership.streetNumber,
          city: selectedPartnership.city,
          country: selectedPartnership.country,
          role: selectedPartnership.role,
        };
      } else {
        // Handle the error condition here
        handleSnackbar({
          content: `Something went terribly wrong! Report this to the developer!`,
          severity: "error",
        });
        return; // Return early in the error case
      }
      setRoleInActivePartnership(partnership.role);
      setActivePartnership(partnership);
      return partnership;
    },
    [getPartnerships, getPartnershipByID, handleSnackbar]
  );

  const login = async (user) => {
    const token = await userApi.login(user);
    localStorage.setItem("token", token);
    const validatedUser = getCurrentUserWithToken();
    setAuth(true);
    handleSnackbar({
      content: `Welcome ${currentUser.name || currentUser.email}`,
      severity: "success",
    });
    setCurrentUser(validatedUser);
    setCurrentPartnerships("default");
  };

  const update = async (newUser) => {
    const data = await userApi.update(newUser);
    if (data.token) {
      localStorage.setItem("token", data.token);
      setAuth(true);
      handleSnackbar({
        content: `Succesfully updated ${currentUser.name || currentUser.email}`,
        severity: "success",
      });
      setCurrentUser(data.updatedUser);
      setCurrentPartnerships("default");
    }
  };

  const registerUser = async (user) => {
    const token = await userApi.register(user);
    localStorage.setItem("token", token);
    const validatedUser = getCurrentUserWithToken();
    setAuth(true);
    setCurrentUser(validatedUser);
    setCurrentPartnerships("default");
  };

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("partnership");
    setAuth(false);
    handleSnackbar({
      content: `See you later ${currentUser.name || currentUser.email}`,
      severity: "success",
    });
    setCurrentUser({});
    setCurrentPartnerships("none");
    setActivePartnership({});
  };

  const verify = useCallback(async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      return false;
    }

    const bool = await userApi.verify(token);

    if (!bool.validated) {
      logout();
      return false;
    }

    if (bool.token) {
      localStorage.setItem("token", bool.token);
    }

    const user = getCurrentUserWithToken();
    setCurrentUser(user);
    setAuth(true);
    if (activePartnership.partnershipID) {
      setCurrentPartnerships("update");
    } else {
      setCurrentPartnerships("default");
    }
    setVerifyTrigger(false); // Disable further verification attempts

    return bool.validated;
  }, [getCurrentUserWithToken, userApi, logout]);

  useEffect(() => {
    if (verifyTrigger) {
      // Only trigger verification if verifyTrigger is true
      async function handleVerify() {
        try {
          await verify();
        } catch (err) {
          // console.log(err);
        }
      }
      handleVerify();
    }
  }, [verifyTrigger]);

  const value = useMemo(
    () => ({
      isAuth,
      login,
      registerUser,
      logout,
      verify,
      getCurrentUserWithToken,
      currentUser,
      activePartnership,
      roleInActivePartnership,
      partnershipList,
      setCurrentPartnerships, // Added partnership
      update,
    }),
    [
      isAuth,
      login,
      registerUser,
      logout,
      verify,
      getCurrentUserWithToken,
      currentUser,
      activePartnership,
      roleInActivePartnership,
      partnershipList,
      setCurrentPartnerships, // Added partnership
      update,
    ]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
