import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Container,
  Typography,
  Box,
  IconButton,
  Tooltip,
  Tabs,
  Tab,
} from "@mui/material";

import { Link as RouterLink } from "react-router-dom";
import useProperty from "../../../api/property";
import PropertyDetails from "./PropertyDetail";
import PropertyPhotos from "./PropertyPhotos";
import PropertyPremises from "./PropertyPremises";
import PropertyDocuments from "./PropertyDocuments";
import { useSnackbarContext } from "../../../context/ui/SnackbarProvider";
import { useDialogContext } from "../../../context/ui/DialogProvider";
import {
  ArrowBack as ArrowBackIcon,
  Delete as DeleteIcon,
  House as HouseIcon,
  PhotoLibrary as PhotoLibraryIcon,
  Room as RoomIcon,
  Description as DescriptionIcon,
} from "@mui/icons-material";
import { Link as Routerlink } from "react-router-dom";
export default function PropertyPage() {
  const { propertyID } = useParams();
  const { handleSnackbar } = useSnackbarContext();
  const { handleDialog } = useDialogContext();
  const [saving, setSave] = useState(false);
  const { getPropertyById, deleteProperty } = useProperty();
  const navigate = useNavigate();
  const [property, setProperty] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const { pathname } = useLocation();
  useEffect(() => {
    async function fetchData() {
      try {
        const propertyData = await getPropertyById(propertyID);
        setProperty(propertyData);
      } catch (error) {
        console.error("Error fetching property:", error);
      }
    }
    if (pathname === `/FolioControl/properties/details/${propertyID}`) {
      setActiveTab(0);
    } else if (pathname === `/FolioControl/properties/photos/${propertyID}`) {
      setActiveTab(1);
    } else if (
      pathname === `/FolioControl/properties/documents/${propertyID}`
    ) {
      setActiveTab(2);
    } else if (pathname === `/FolioControl/properties/premises/${propertyID}`) {
      setActiveTab(3);
    }

    fetchData();
    setSave(false);
  }, [propertyID, saving]);

  const handleDeleteProperty = async () => {
    try {
      await deleteProperty(propertyID);
      handleSnackbar({
        content: "Successfully deleted property",
      });
      navigate("/FolioControl/properties");
    } catch (error) {
      console.error("Error deleting property:", error);
    }
  };

  const deletePropertyDialog = () => {
    handleDialog({
      title: "Delete property",
      content: "Confirmation",
      moreInfo: `Are you sure you want to delete ${property?.propertyName}`,
      confirmText: "DELETE PROPERTY",
      ifErrorText: `Failed to delete property`,
      ifSuccessText: "Successfully deleted property!",
      onConfirm: handleDeleteProperty,
    });
  };

  return (
    <Container sx={{ pt: "3rem" }}>
      <Box display="flex" alignItems="center" mb={3}>
        <IconButton
          component={RouterLink}
          to="/FolioControl/properties"
          color="primary"
        >
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h4" gutterBottom>
          {property?.propertyName}
        </Typography>
        <Tooltip title="Delete Property">
          <IconButton
            onClick={deletePropertyDialog}
            sx={{ marginLeft: "auto" }}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <Tabs
        value={activeTab}
        onChange={(event, newValue) => setActiveTab(newValue)}
        centered
      >
        <Tab
          label="Property Details"
          icon={<HouseIcon />}
          LinkComponent={Routerlink}
          to={`/FolioControl/properties/details/${propertyID}`}
        />
        <Tab
          label="Photos"
          icon={<PhotoLibraryIcon />}
          LinkComponent={Routerlink}
          to={`/FolioControl/properties/photos/${propertyID}`}
        />
        <Tab
          label="Documents"
          icon={<DescriptionIcon />}
          LinkComponent={Routerlink}
          to={`/FolioControl/properties/documents/${propertyID}`}
        />
        {/* Conditionally render the "Premises" tab based on property type */}
        {property?.propertyType !== "Storage" &&
          property?.propertyType !== "Garage" &&
          property?.propertyType !== "House" &&
          property?.propertyType !== "Terraced House" &&
          property?.propertyType !== "Semi-detached" &&
          property?.propertyType !== "" && (
            <Tab
              label="Premises"
              icon={<RoomIcon />}
              LinkComponent={Routerlink}
              to={`/FolioControl/properties/premises/${propertyID}`}
            />
          )}
      </Tabs>

      {property?.propertyType && (
        <>
          {activeTab === 0 && (
            <PropertyDetails property={property} setSave={setSave} />
          )}
          {activeTab === 1 && (
            <PropertyPhotos property={property} setSave={setSave} />
          )}
          {activeTab === 2 && <PropertyDocuments property={property} />}
          {/* Render "PropertyPremises" only if the property type is not 'Percel' */}
          {property.propertyType !== "Storage" &&
            property.propertyType !== "Garage" &&
            property.propertyType !== "House" &&
            property.propertyType !== "" &&
            activeTab === 3 && <PropertyPremises property={property} />}
        </>
      )}
    </Container>
  );
}
