import axios from 'axios';
import { useCallback, useMemo } from 'react';

const baseUrl = `${process.env.REACT_APP_API_URL}/partnership_user`;

const usePartnershipConnection = () => {
  //leave partnership
  const leavePartnership = useCallback(async () => {
    await axios({
      method: 'PUT',
      url: `${baseUrl}/leave`,
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });
  }, []);

  //getRole of logged in user
  const getRole = useCallback(async () => {
    await axios({
      method: 'GET',
      url: `${baseUrl}/role`,
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });
  }, []);
  //getMembersByPartnershipID
  const getMembersByPartnershipID = useCallback(async (partnershipID) => {
    const { data } = await axios({
      method: 'GET',
      url: `${baseUrl}/${partnershipID}`,
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });
    return data;
  }, []);

  const partnershipConnectionApi = useMemo(
    () => ({
      leavePartnership,
      getRole,
      getMembersByPartnershipID,
    }),
    [leavePartnership, getRole, getMembersByPartnershipID],
  );
  return partnershipConnectionApi;
};
export default usePartnershipConnection;
