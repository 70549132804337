import React from "react";

// mui
import {
  Divider,
  ListItemButton,
  ListItemIcon,
  Tooltip,
  Badge,
} from "@mui/material";
import {
  Add,
  AddHomeWork,
  DomainAdd,
  NotificationsActive as NotificationsActiveIcon,
} from "@mui/icons-material";
import MapsHomeWorkIcon from "@mui/icons-material/MapsHomeWork";
import { NavLink, useLocation } from "react-router-dom";
import {
  MyList,
  ActionIconsContainerDesktop,
  ActionIconsContainerMobile,
} from "../../styles/navbar";
import { Colors } from "../../styles/themes";
import AuthButton from "../authentication/AuthButton";
import { useAuthContext } from "../../context/AuthProvider";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
export default function Actions({ matches }) {
  const Component = matches
    ? ActionIconsContainerMobile
    : ActionIconsContainerDesktop;
  const { isAuth } = useAuthContext();
  const { pathname } = useLocation();

  const activeLinkStyle = {
    color: Colors.white,
    backgroundColor: Colors.bottom,
    borderRadius: "2rem",
  };
  if (isAuth) {
    return (
      <Component>
        <MyList type="row">
          <Tooltip title="FolioControl">
            <NavLink to="/FolioControl">
              <ListItemButton
                sx={{
                  justifyContent: "center",
                }}
              >
                <ListItemIcon
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    color: Colors.primary,
                    ":hover": {
                      color: Colors.white,
                      backgroundColor: Colors.bottom,
                      borderRadius: "2rem",
                    },
                    ...(pathname === "/FolioControl" ? activeLinkStyle : {}),
                  }}
                >
                  <Badge color="primary">
                    <AccountBalanceIcon sx={{ fontSize: "2rem" }} />
                  </Badge>
                </ListItemIcon>
              </ListItemButton>
            </NavLink>
          </Tooltip>
          <Divider orientation="vertical" flexItem />
          <Tooltip title="Properties">
            <NavLink to="/FolioControl/properties">
              <ListItemButton
                sx={{
                  justifyContent: "center",
                }}
              >
                <ListItemIcon
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    color: Colors.primary,
                    ":hover": {
                      color: Colors.white,
                      backgroundColor: Colors.bottom,
                      borderRadius: "2rem",
                    },
                    ...(pathname === "/FolioControl/properties"
                      ? activeLinkStyle
                      : {}),
                  }}
                >
                  <MapsHomeWorkIcon sx={{ fontSize: "2rem" }} />
                </ListItemIcon>
              </ListItemButton>
            </NavLink>
          </Tooltip>
          <Divider orientation="vertical" flexItem />
          <Tooltip title="Add Property">
            <NavLink to="/FolioControl/properties/add">
              <ListItemButton
                sx={{
                  justifyContent: "center",
                }}
              >
                <ListItemIcon
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    color: Colors.primary,
                    ":hover": {
                      color: Colors.white,
                      backgroundColor: Colors.bottom,
                      borderRadius: "2rem",
                    },
                    ...(pathname === "/FolioControl/properties/add"
                      ? activeLinkStyle
                      : {}),
                  }}
                >
                  <Badge color="primary">
                    <AddHomeWork sx={{ fontSize: "2rem" }} />
                  </Badge>
                </ListItemIcon>
              </ListItemButton>
            </NavLink>
          </Tooltip>

          <Divider orientation="vertical" flexItem />
          <Tooltip title="Notifications">
            <NavLink to="/notifications">
              <ListItemButton
                sx={{
                  justifyContent: "center",
                }}
              >
                <ListItemIcon
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    fontSize: "2rem",
                    color: Colors.primary,
                    ":hover": {
                      color: Colors.white,
                      backgroundColor: Colors.bottom,
                      borderRadius: "2rem",
                    },
                    ...(pathname === "/notifications" ? activeLinkStyle : {}),
                  }}
                >
                  <Badge color="primary">
                    <NotificationsActiveIcon sx={{ fontSize: "2rem" }} />
                  </Badge>
                </ListItemIcon>
              </ListItemButton>
            </NavLink>
          </Tooltip>

          <Divider orientation="vertical" flexItem />
          <AuthButton matches={matches} />
        </MyList>
      </Component>
    );
  } else {
    return (
      <Component>
        <MyList type="row">
          <AuthButton matches={matches} />
        </MyList>
      </Component>
    );
  }
}
