import axios from 'axios';
import { useCallback, useMemo } from 'react';

const baseUrl = `${process.env.REACT_APP_API_URL}/premises`;

const usePremises = () => {
  //create partnership
  const createPremise = useCallback(async (premisesDetails) => {
    await axios({
      method: 'POST',
      url: `${baseUrl}/create`,
      headers: {
        Authorization: localStorage.getItem('token'),
      },
      data: premisesDetails,
    });
  }, []);

  //get premises by id
  const getPremisesByID = useCallback(async (premisesID) => {
    const body = await axios({
      method: 'GET',
      url: `${baseUrl}/${premisesID}`,
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });
    return body.data;
  }, []);

  //get premises by property id
  const getPremisesByProperty = useCallback(async (propertyID) => {
    const body = await axios({
      method: 'GET',
      url: `${baseUrl}/property/${propertyID}`,
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });
    return body.data;
  }, []);

  //delete premises
  const deletePremises = useCallback(async (premisesID) => {
    await axios({
      method: 'DELETE',
      url: `${baseUrl}/${premisesID}`,
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });
  }, []);

  //update premises
  const updatePremises = useCallback(async (premisesDetails) => {
    console.log(premisesDetails);
    await axios({
      method: 'PUT',
      url: `${baseUrl}/${premisesDetails.premisesID}`,
      headers: {
        Authorization: localStorage.getItem('token'),
      },
      data: premisesDetails,
    });
  }, []);

  const premisesApi = useMemo(
    () => ({
      createPremise,
      getPremisesByID,
      getPremisesByProperty,
      deletePremises,
      updatePremises,
    }),
    [createPremise, getPremisesByID, getPremisesByProperty, deletePremises, updatePremises],
  );
  return premisesApi;
};

export default usePremises;
