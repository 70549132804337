import { Box, Button, Container, Grid, ImageList, ImageListItem, Paper, Typography } from '@mui/material';
import { CloudUpload as CloudUploadIcon } from '@mui/icons-material';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  paper: {
    padding: '1rem',
    width: '100%',
  },
};

export default function PremisesPhotos({ premises }) {
  const handleUploadPhoto = () => {
    // IPhoto logic
  };

  return (
    <Container maxWidth='lg'>
      <Box sx={styles.container}>
        <Paper elevation={3} sx={styles.paper}>
          <Typography variant='h5' gutterBottom>
            Premises Photos
          </Typography>
          <Grid container spacing={2}>
            {premises.images && premises.images.length > 0 ? (
              <ImageList sx={{ width: 500, height: 450 }} cols={3} rowHeight={164}>
                {premises.map((item) => (
                  <ImageListItem key={item.img}>
                    <img srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`} src={`${item.img}?w=164&h=164&fit=crop&auto=format`} alt={item.title} loading='lazy' />
                  </ImageListItem>
                ))}
              </ImageList>
            ) : (
              // Default image or message when there are no property images
              <Grid item xs={12} align='center'>
                <img src={process.env.PUBLIC_URL + '/images/LexsIT/_noimage.jpg?w=164&h=164&fit=crop&auto=format&dpr=2 2x'} alt='Premises' width={'30%'} loading='lazy' />
              </Grid>
            )}
          </Grid>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 2 }}>
            <Typography variant='h6'>Upload Photo</Typography>
            <input type='file' accept='image/*' style={{ display: 'none' }} />
            <label htmlFor='photo-upload'>
              <Button variant='outlined' color='primary' startIcon={<CloudUploadIcon />} component='span' onClick={handleUploadPhoto}>
                Upload
              </Button>
            </label>
          </Box>
        </Paper>
      </Box>
    </Container>
  );
}
