import React from "react";
import { Button, Grid, Typography, Divider, Paper } from "@mui/material";
import useUser from "../../api/user";
import { useAuthContext } from "../../context/AuthProvider";
import { useDialogContext } from "../../context/ui/DialogProvider";

export default function AccountOptions() {
  const { verify, logout } = useAuthContext();
  const { deleteUser, leavePartnership } = useUser();
  const { handleDialog } = useDialogContext();

  const handleDelete = async () => {
    await deleteUser();
    await logout();
  };

  const handleLeave = async () => {
    await leavePartnership();
    await verify();
  };

  const deleteUserDialog = () => {
    handleDialog({
      title: "Delete Account",
      content: "Are you sure you want to delete your account?",
      confirmText: "DELETE ACCOUNT",
      ifErrorText: "Failed to delete account",
      ifSuccessText: "Successfully deleted account!",
      onConfirm: handleDelete,
    });
  };

  const leavePartnershipDialog = () => {
    handleDialog({
      title: "Leave Partnership",
      content: "Are you sure you want to leave your partnership?",
      confirmText: "Leave Partnership",
      ifErrorText: "Failed to leave partnership",
      ifSuccessText: "Successfully left partnership!",
      onConfirm: handleLeave,
    });
  };

  const deletePartnershipDialog = () => {
    handleDialog({
      title: "Delete Partnership",
      content: "Are you sure you want to delete your partnership?",
      confirmText: "Delete Partnership",
      ifErrorText: "Failed to delete partnership",
      ifSuccessText: "Successfully deleted partnership!",
      // onConfirm: handleDeletePartnership,
    });
  };

  const changePasswordDialog = () => {
    handleDialog({
      title: "Change Password",
      content: "You can change your password here.",
      confirmText: "Change Password",
      ifErrorText: "Failed to change password",
      ifSuccessText: "Password changed successfully!",
      // onConfirm: handleChangePassword,
    });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Paper sx={{ margin: { md: "20px 0 20px auto", sm: "20px auto" } }}>
          <Typography variant="h4">Account Settings</Typography>
          <Divider sx={{ my: 2 }} />
          <Button onClick={deleteUserDialog} variant="contained" fullWidth>
            Delete Account
          </Button>
          <Button
            onClick={changePasswordDialog}
            variant="contained"
            fullWidth
            sx={{ mt: 2 }}
          >
            Change Password
          </Button>
        </Paper>
      </Grid>
      <Grid item xs={12} md={6}>
        <Paper sx={{ margin: { md: "20px auto 20px 0", sm: "20px auto" } }}>
          <Typography variant="h4">Partnerships</Typography>
          <Divider sx={{ my: 2 }} />
          <Button
            onClick={leavePartnershipDialog}
            variant="contained"
            fullWidth
          >
            Leave Partnership
          </Button>
          <Button
            onClick={deletePartnershipDialog}
            variant="contained"
            fullWidth
            sx={{ mt: 2 }}
          >
            Delete Partnership
          </Button>
        </Paper>
      </Grid>
    </Grid>
  );
}
