import React, { useState } from "react";
import {
  TextField,
  Switch,
  Typography,
  Container,
  Paper,
  Box,
  Button,
  Autocomplete,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import ErrorIcon from "@mui/icons-material/Error";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import BusinessIcon from "@mui/icons-material/Business";
import usePartnership from "../../../api/partnership";
import { useSnackbarContext } from "../../../context/ui/SnackbarProvider";
import { useAuthContext } from "../../../context/AuthProvider";
import { useNavigate } from "react-router-dom";

export default function AddPartnership() {
  const partershipApi = usePartnership();
  const { handleSnackbar } = useSnackbarContext();
  const { verify } = useAuthContext();
  const navigate = useNavigate();
  const [countryCode, setCountryCode] = useState("No country selected");
  const [loadingButton, setLoadingButton] = useState(false);
  const [customFormEnabled, setCustomFormEnabled] = useState(true);
  const [companyData, setCompanyData] = useState({
    valid: false,
    countryCode: "",
    vatNumber: "",
    name: "",
    street: "",
    number: "",
    zipCode: "",
    city: "",
    country: "",
  });
  const [error, setError] = useState("");

  const handleChange = (field) => (event) => {
    const value = event.target.value;

    setCompanyData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
    setError("");
  };
  const handleCountryChange = (event, newValue) => {
    if (newValue) {
      setCountryCode(newValue.countryCode);
      setCompanyData((prevData) => ({
        ...prevData,
        countryCode: newValue.countryCode,
        country: newValue.label,
      }));
    }
  };

  const handleSetCompany = async () => {
    setLoadingButton(true);
    try {
      const companyInformation = await partershipApi.checkVat(
        companyData.vatNumber
      );
      if (companyInformation.valid) {
        setCompanyData({
          ...companyData,
          ...formatCompany(companyInformation),
        });
        handleSnackbar({
          content: `Successfully added company information for ${companyInformation.name}`,
          icon: <CheckIcon />,
        });
      } else {
        setError(
          "Company does not exist; make sure to format it like BE0123456789"
        );
        handleSnackbar({
          content:
            "Company does not exist; make sure to format it like BE0123456789",
          severity: "error",
          icon: <ErrorIcon />,
        });
      }
    } catch (e) {
      setError("An error occurred while validating the VAT number");
      handleSnackbar({
        content: "An error occurred while validating the VAT number",
        severity: "error",
        icon: <ErrorIcon />,
      });
    }
    setLoadingButton(false);
  };
  const isCreateButtonDisabled = () => {
    const {
      name,
      countryCode,
      vatNumber,
      street,
      number,
      zipCode,
      city,
      country,
    } = companyData;
    return (
      vatNumber === "" ||
      name === "" ||
      countryCode === "" ||
      street === "" ||
      number === "" ||
      zipCode === "" ||
      city === "" ||
      country === "" ||
      loadingButton
    );
  };

  const handleRegisterPartnership = async () => {
    setLoadingButton(true);
    try {
      const {
        name,
        countryCode,
        vatNumber,
        street,
        number,
        zipCode,
        city,
        country,
      } = companyData;

      if (
        name &&
        countryCode &&
        vatNumber &&
        street &&
        number &&
        zipCode &&
        city &&
        country
      ) {
        const newPartnership = {
          name,
          countryCode,
          vatNumber,
          street,
          streetNumber: number,
          zipCode,
          city,
          country,
        };

        await partershipApi.createPartnership(newPartnership);
        await verify();
        handleSnackbar({
          content: "Successfully created partnership",
          icon: <CheckIcon />,
        });

        navigate("/FolioControl", { replace: true });
      } else {
        setError("Please fill in all required fields");
        handleSnackbar({
          content: "Please fill in all required fields",
          severity: "error",
          icon: <ErrorIcon />,
        });
      }
    } catch (e) {
      handleSnackbar({
        content:
          "Something went wrong while creating the partnership, make sure you filled in all fields correctly",
        severity: "error",
        icon: <ErrorIcon />,
      });
    }
    setLoadingButton(false);
  };

  const handleSwitch = (event) => {
    setCustomFormEnabled(event.target.checked);
  };

  const formatCompany = (companyInformation) => ({
    name: companyInformation.name,
    countryCode: companyInformation.countryCode,
    vatNumber: companyInformation.vatNumber,
    street: companyInformation.address.street,
    number: companyInformation.address.number,
    zipCode: companyInformation.address.zip_code,
    city: companyInformation.address.city,
    country: companyInformation.address.country,
  });
  const countries = [
    { label: "Austria", countryCode: "AT" },
    { label: "Belgium", countryCode: "BE" },
    { label: "Bulgaria", countryCode: "BG" },
    { label: "Croatia", countryCode: "HR" },
    { label: "Cyprus", countryCode: "CY" },
    { label: "Czech Republic", countryCode: "CZ" },
    { label: "Denmark", countryCode: "DK" },
    { label: "Estonia", countryCode: "EE" },
    { label: "Finland", countryCode: "FI" },
    { label: "France", countryCode: "FR" },
    { label: "Germany", countryCode: "DE" },
    { label: "Greece", countryCode: "GR" },
    { label: "Hungary", countryCode: "HU" },
    { label: "Ireland", countryCode: "IE" },
    { label: "Italy", countryCode: "IT" },
    { label: "Latvia", countryCode: "LV" },
    { label: "Lithuania", countryCode: "LT" },
    { label: "Luxembourg", countryCode: "LU" },
    { label: "Malta", countryCode: "MT" },
    { label: "Nederland", countryCode: "NL" },
    { label: "Poland", countryCode: "PL" },
    { label: "Portugal", countryCode: "PT" },
    { label: "Romania", countryCode: "RO" },
    { label: "Slovakia", countryCode: "SK" },
    { label: "Slovenia", countryCode: "SI" },
    { label: "Spain", countryCode: "ES" },
    { label: "Sweden", countryCode: "SE" },
  ];

  return (
    <Container maxWidth="sm">
      <Paper elevation={3} sx={{ padding: 4 }}>
        <Typography variant="h4" align="center" gutterBottom>
          Create a Partnership
        </Typography>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "8px",
          }}
        >
          <BusinessIcon color="primary" fontSize="large" />
          <Typography variant="body1" color="textSecondary">
            Use a VAT number to automatically fill in company information.
          </Typography>
          <Switch
            checked={customFormEnabled}
            onChange={handleSwitch}
            inputProps={{ "aria-label": "controlled" }}
          />
        </Box>

        <TextField
          sx={{ my: "5px" }}
          label="VAT Number"
          variant="outlined"
          fullWidth
          onChange={handleChange("vatNumber")}
          value={companyData.vatNumber}
        />

        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleSetCompany}
          disabled={!customFormEnabled || loadingButton}
          startIcon={<LocationOnIcon />}
        >
          Validate VAT Number
        </Button>

        {error && (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <ErrorIcon color="error" />
            <Typography color="error">{error}</Typography>
          </Box>
        )}

        <Typography variant="h5" mt={3} mb={2}>
          Company Information
        </Typography>

        <TextField
          sx={{ my: "4px" }}
          label="Name"
          variant="outlined"
          fullWidth
          onChange={handleChange("name")}
          value={companyData.name}
          disabled={customFormEnabled}
        />
        <TextField
          sx={{ my: "4px" }}
          label="VAT Number"
          variant="outlined"
          fullWidth
          onChange={handleChange("vatNumber")}
          value={companyData.vatNumber}
          disabled={customFormEnabled}
        />
        <TextField
          sx={{ my: "4px" }}
          label="Street"
          variant="outlined"
          fullWidth
          onChange={handleChange("street")}
          value={companyData.street}
          disabled={customFormEnabled}
        />
        <TextField
          sx={{ my: "4px" }}
          label="Street Number"
          variant="outlined"
          fullWidth
          onChange={handleChange("number")}
          value={companyData.number}
          disabled={customFormEnabled}
        />
        <TextField
          sx={{ my: "4px" }}
          label="City"
          variant="outlined"
          fullWidth
          onChange={handleChange("city")}
          value={companyData.city}
          disabled={customFormEnabled}
        />
        <TextField
          sx={{ my: "4px" }}
          label="Zip Code"
          variant="outlined"
          fullWidth
          onChange={handleChange("zipCode")}
          value={companyData.zipCode}
          disabled={customFormEnabled}
        />
        <Autocomplete
          fullWidth
          value={companyData.country}
          disabled={customFormEnabled}
          options={countries} // Use an array of country names
          freeSolo // Allow custom values
          onChange={handleCountryChange}
          renderInput={(params) => (
            <TextField
              sx={{ my: "4px" }}
              label="Country"
              variant="outlined"
              fullWidth
              {...params}
            />
          )}
        />
        <TextField
          sx={{ my: "4px" }}
          label="Country Code"
          disabled
          variant="outlined"
          fullWidth
          onChange={handleChange("countryCode")}
          value={companyData.countryCode || countryCode}
        />
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleRegisterPartnership}
          disabled={isCreateButtonDisabled()}
          startIcon={<CheckIcon />}
        >
          Create Partnership
        </Button>
      </Paper>
    </Container>
  );
}
