import React, { useEffect, useState } from 'react';
import { Typography, Paper, Box, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Switch, Skeleton, Button, CardActions, Container } from '@mui/material';
import { Add, Add as AddIcon, Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';
import usePremises from '../../../api/premises';

import { Link as RouterLink } from 'react-router-dom';
import { useDialogContext } from '../../../context/ui/DialogProvider';
export default function PropertyPremises({ property }) {
  const [premisesList, setPremisesList] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state
  const { getPremisesByProperty, deletePremises } = usePremises();
  const { handleDialog } = useDialogContext();

  const handleDeletePremise = (index, premise) => {
    console.log(index, premise);
    const updatedPremises = [...premisesList];
    updatedPremises.splice(index, 1);
    try {
      deletePremises(premise.premisesID);
      setPremisesList(updatedPremises);
    } catch (error) {
      console.error('Error deleting premises:', error);
    }
  };

  const handleChangeArea = (index, field, value) => {
    const updatedPremises = [...premisesList];
    updatedPremises[index][field] = value;
    setPremisesList(updatedPremises);
  };

  async function getPremises() {
    const response = await getPremisesByProperty(property.propertyID);
    setPremisesList(response);
    setLoading(false); // Set loading to false once data is loaded
  }

  const deletePropertyDialog = (index, premise) => {
    handleDialog({
      title: 'Delete Premises',
      content: 'Confirmation',
      moreInfo: `Are you sure you want to delete ${premise?.premisesName}`,
      confirmText: 'DELETE PREMISES',
      ifErrorText: `Failed to delete premises`,
      ifSuccessText: 'Successfully deleted premises!',
      onConfirm: () => handleDeletePremise(index, premise),
    });
  };

  useEffect(() => {
    getPremises();
  }, [property]);

  return (
    <Container sx={{ display: 'flex', justifyContent: 'center', height: '100vh', width: '100%' }}>
      <Box sx={{ display: 'block', width: '100%' }}>
        <Paper elevation={3} sx={{ padding: '1rem 3rem 1rem 3rem', width: '100%' }}>
          <Typography variant='h5' gutterBottom>
            Premises
          </Typography>
          {loading ? (
            // Skeleton loading state while data is loading
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Bus</TableCell>
                    <TableCell>Rent</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Active</TableCell>
                    <TableCell>Is Rented</TableCell>
                    <TableCell>Tenant</TableCell>
                    <TableCell>Delete</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Array.from([{ key: 1 }, { key: 2 }, { key: 3 }, { key: 4 }, { key: 5 }]).map((index) => (
                    <TableRow key={index.key}>
                      <TableCell>
                        <Skeleton variant='text' width={100} />
                      </TableCell>
                      <TableCell>
                        <Skeleton variant='text' width={80} />
                      </TableCell>
                      <TableCell>
                        <Skeleton variant='text' width={80} />
                      </TableCell>
                      <TableCell>
                        <Skeleton variant='text' width={100} />
                      </TableCell>
                      <TableCell>
                        <Skeleton variant='text' width={50} />
                      </TableCell>
                      <TableCell>
                        <Skeleton variant='text' width={50} />
                      </TableCell>
                      <TableCell>
                        <Skeleton variant='text' width={100} />
                      </TableCell>
                      <TableCell>
                        <Skeleton variant='circle' width={30} height={30} />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Bus</TableCell>
                    <TableCell>Rent</TableCell>
                    <TableCell>Active</TableCell>
                    <TableCell>Is Rented</TableCell>
                    <TableCell>Tenant</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {premisesList.length > 0 ? (
                    premisesList.map((premise, index) => (
                      <TableRow key={index}>
                        <TableCell>{premise.premisesName}</TableCell>
                        <TableCell>{premise.bus}</TableCell>
                        <TableCell>{premise.rent}</TableCell>
                        <TableCell>
                          <Switch disabled={true} checked={premise.isActive !== 0} onChange={(e) => handleChangeArea(index, 'isActive', e.target.checked)} />
                        </TableCell>
                        <TableCell>
                          <Switch disabled={true} checked={premise.isRented !== 0} onChange={(e) => handleChangeArea(index, 'isRented', e.target.checked)} />
                        </TableCell>
                        <TableCell>{premise.tenant}</TableCell>
                        <TableCell>
                          <IconButton component={RouterLink} to={`/FolioControl/properties/premises/premise/${premise.premisesID}`}>
                            <EditIcon />
                          </IconButton>
                          <IconButton onClick={() => deletePropertyDialog(index, premise)}>
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell>No premises found</TableCell>
                      <TableCell>Add them first</TableCell>
                      <TableCell>0</TableCell>
                      <TableCell>
                        <Switch disabled={true} checked={false} />
                      </TableCell>
                      <TableCell>
                        <Switch disabled={true} checked={false} />
                      </TableCell>
                      <TableCell>/</TableCell>
                      <TableCell>
                        <IconButton component={RouterLink} to={`/FolioControl/properties/addPremises/${property.propertyID}`}>
                          <Add />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}

          <CardActions>
            <Button variant='contained' component={RouterLink} to={`/FolioControl/properties/addPremises/${property.propertyID}`}>
              <AddIcon /> Add Premises
            </Button>
          </CardActions>
        </Paper>
      </Box>
    </Container>
  );
}
