import axios from 'axios';
import { useCallback, useMemo } from 'react';

const baseUrl = `${process.env.REACT_APP_API_URL}/property`;

const useProperty = () => {
  //Partnership options
  //create partnership
  const createProperty = useCallback(async (propertyDetails) => {
    await axios({
      method: 'POST',
      url: `${baseUrl}/create`,
      headers: {
        Authorization: localStorage.getItem('token'),
        'Content-Type': 'multipart/form-data',
      },
      data: propertyDetails,
    });
  }, []);

  //get properties by partnership id
  const getPropertiesByPartnershipID = useCallback(async (partnershipID) => {
    const body = await axios({
      method: 'GET',
      url: `${baseUrl}/partnership/${partnershipID}`,
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });

    return body.data;
  }, []);

  //get property by property id
  //get properties by partnership id
  const getPropertyById = useCallback(async (propertyID) => {
    const body = await axios({
      method: 'GET',
      url: `${baseUrl}/${propertyID}`,
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });
    return body.data;
  }, []);

  //delete property
  const deleteProperty = useCallback(async (propertyID) => {
    await axios({
      method: 'DELETE',
      url: `${baseUrl}/${propertyID}`,
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });
  }, []);

  const updateProperty = useCallback(async ({ propertyID, ...propertyData }) => {
    await axios({
      method: 'PUT',
      url: `${baseUrl}/${propertyID}`,
      headers: {
        Authorization: localStorage.getItem('token'),
        'Content-Type': 'multipart/form-data',
      },
      data: propertyData,
    });
  }, []);

  const partnershipApi = useMemo(
    () => ({
      createProperty,
      getPropertiesByPartnershipID,
      getPropertyById,
      deleteProperty,
      updateProperty,
    }),
    [createProperty, getPropertiesByPartnershipID, getPropertyById, deleteProperty, updateProperty],
  );
  return partnershipApi;
};

export default useProperty;
