import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Box,
  Grid,
  Card,
  CardContent,
  CardActions,
  Button,
  IconButton,
  Tooltip,
  CircularProgress,
  useMediaQuery,
  useTheme,
  Avatar,
  Fade,
} from "@mui/material";

import { LocationOn as LocationOnIcon } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { Link as RouterLink } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SearchIcon from "@mui/icons-material/Search";
import useProperty from "../../../api/property";
import { useAuthContext } from "../../../context/AuthProvider";

import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Colors } from "../../../styles/themes";
import {
  Search,
  SearchIconWrapper,
  StyledInputBase,
} from "../../../styles/navbar";
import { PropertyTypes } from "../../../common";
export default function PropertyList() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const cardRadius = "8px";
  const cardOverflow = "hidden";

  const cardStyle = {
    minWidth: "100%",
    minHeight: "100%",
    borderRadius: cardRadius,
    overflow: cardOverflow,
    display: "flex",
    flexDirection: "column",
  };
  const { getPropertiesByPartnershipID } = useProperty();
  const [loading, setLoading] = useState(true);
  const [selectedPartnership, setSelectedPartnership] = useState("");
  const [properties, setProperties] = useState([]);
  const { activePartnership } = useAuthContext();
  const imageUrl = process.env.REACT_APP_PROPERTYPHOTOS_URL;
  useEffect(() => {
    setSelectedPartnership(activePartnership);
    async function fetchProperties() {
      if (selectedPartnership) {
        try {
          const propertyData = await getPropertiesByPartnershipID(
            selectedPartnership.partnershipID
          );

          setProperties(propertyData);
          setLoading(false);
        } catch (error) {
          console.error("Error fetching properties:", error);
        }
      }
    }

    fetchProperties();
  }, [selectedPartnership, activePartnership, getPropertiesByPartnershipID]);

  const [searchTerm, setSearchTerm] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = isMobile ? 4 : 8; // Number of items to display per page
  const clearSearch = () => {
    setSearchTerm("");
  };
  const filteredProperties = properties.filter(
    ({ propertyType, propertyName }) => {
      const lowerSearchTerm = searchTerm.toLowerCase();
      const matchType = propertyType.toLowerCase().includes(lowerSearchTerm);
      const matchName = propertyName.toLowerCase().includes(lowerSearchTerm);
      return matchType || matchName;
    }
  );

  // Calculate the total number of pages
  var totalPages = 0;
  if (filteredProperties.length > 1) {
    totalPages = Math.ceil(filteredProperties.length / itemsPerPage);
  }

  // Slice the properties array to display only the items for the current page
  const displayedProperties = filteredProperties.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  // Function to handle page change
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <Container sx={{ minWidth: "100%" }}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={1}
      >
        <Box>
          <IconButton component={RouterLink} to="/FolioControl" color="primary">
            <ArrowBackIcon />
          </IconButton>
        </Box>
        <Tooltip title="Toggle Name Search">
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              inputProps={{ "aria-label": "Search" }}
              value={searchTerm}
              onClick={(e) => {
                e.stopPropagation(); /* Prevents closing the tooltip */
              }}
              onChange={(e) => {
                setSearchTerm(e.target.value);
                handlePageChange(1);
              }}
            />
            {searchTerm && (
              <Button onClick={clearSearch}>
                <CloseIcon />
              </Button>
            )}
          </Search>
        </Tooltip>
      </Box>

      {/* Add loading indicator and "No partnerships found" message */}
      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="100px"
        >
          <CircularProgress />
        </Box>
      ) : filteredProperties.length === 0 ? (
        <Box sx={{ height: "77vh", maxHeight: "77vh", overflow: "scroll" }}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            minHeight="100%"
          >
            <ErrorOutlineIcon sx={{ fontSize: "5rem", color: "error.main" }} />
            <Typography variant="h4" gutterBottom>
              No properties found
            </Typography>
            <Typography variant="body1" color="textSecondary" gutterBottom>
              You first have to add properties before viewing them.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              component={RouterLink}
              to={`/FolioControl/properties/add`}
            >
              Add them here
            </Button>
          </Box>
        </Box>
      ) : (
        <Box sx={{ height: "77vh", maxHeight: "77vh", overflow: "scroll" }}>
          <Grid container spacing={isMobile ? 2 : 4}>
            {displayedProperties.map((property, index) => (
              <Fade
                in={true}
                key={property.propertyID}
                timeout={80 + index * 100}
              >
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  key={property.propertyID}
                >
                  <Card elevation={3} sx={cardStyle}>
                    <div
                      style={{
                        backgroundImage:
                          property.propertyImg !== "null"
                            ? `url(${imageUrl}/${property.propertyImg})`
                            : `url(${imageUrl}/default.avif)`,
                        height: "150px",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                      }}
                    >
                      <Avatar
                        variant="rounded"
                        sx={{
                          width: "40px",
                          height: "40px",
                          backgroundColor: Colors.secondary,
                          border: `2px solid ${Colors.bottom}}`,
                        }}
                      >
                        {
                          PropertyTypes.find(
                            (propertyType) =>
                              propertyType.type === property.propertyType
                          )?.icon
                        }
                      </Avatar>
                    </div>
                    <CardContent sx={{ minHeight: "100%" }}>
                      <Typography variant="h6" gutterBottom>
                        {property.propertyName}
                      </Typography>
                      <Typography variant="subtitle2">
                        <LocationOnIcon />
                        {`${property.street} ${property.streetNumber}, ${property.city} ${property.zipCode}`}
                      </Typography>
                      <Typography variant="subtitle1">
                        {
                          PropertyTypes.find(
                            (propertyType) =>
                              propertyType.type === property.propertyType
                          )?.icon
                        }
                        {property.propertyType}
                      </Typography>
                    </CardContent>
                    <CardActions sx={{ marginTop: "auto" }}>
                      <Button
                        sx={{ minWidth: "100%" }}
                        variant="outlined"
                        component={RouterLink}
                        to={`details/${property.propertyID}`}
                      >
                        View Property
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              </Fade>
            ))}
          </Grid>
        </Box>
      )}
      {/* Pagination controls */}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ marginTop: "10px" }}
        flexDirection={"row"}
      >
        <Button
          variant="outlined"
          disabled={currentPage === 1}
          sx={{
            flex: 1,
            textAlign: isMobile ? "center" : "center",
            margin: isMobile ? "0.5rem 0" : "0 0.5rem", // Added margin for spacing
          }}
          onClick={() => handlePageChange(currentPage - 1)}
        >
          <ArrowBackIos />
        </Button>
        <Typography variant="body2" sx={{ flex: 1, textAlign: "center" }}>
          Page {currentPage} of {totalPages}
        </Typography>
        <Button
          variant="outlined"
          disabled={currentPage === totalPages || totalPages === 0}
          sx={{
            flex: 1,
            textAlign: isMobile ? "center" : "center",
            margin: isMobile ? "0.5rem 0" : "0 0.5rem", // Added margin for spacing
          }}
          onClick={() => handlePageChange(currentPage + 1)}
        >
          <ArrowForwardIosIcon />
        </Button>
      </Box>
    </Container>
  );
}
