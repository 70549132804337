import React from 'react';
import { Typography } from '@mui/material';
import { BannerContainer, BannerContent, BannerDescription, BannerTitle } from '../../styles/banner';

export default function Banner() {
  return (
    <BannerContainer
      sx={{
        color: 'white',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundImage: `url(${process.env.PUBLIC_URL}/MainImage.jpeg)`,
      }}
    >
      <BannerContent sx={{ mr: 'auto' }}>
        <Typography variant='h6' data-cy='homeBanner'>
          Simplifying Your Portfolio Management
        </Typography>
        <BannerTitle variant='h2'> FolioControl</BannerTitle>

        <BannerDescription variant='subtitle' sx={{ fontSize: '20px' }}>
          Welcome to FolioControl, the comprehensive portfolio control app designed to streamline your real estate management journey. Whether you're a property investor, landlord, or property
          manager, FolioControl offers a powerful suite of features to help you efficiently manage your portfolio with ease.
        </BannerDescription>
      </BannerContent>
    </BannerContainer>
  );
}
