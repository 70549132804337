import { ThemeProvider } from "@mui/material/styles";
import * as React from "react";
import theme from "./styles/themes";
import ContextWrapper from "./context/ContextWrapper";
import { CssBaseline, useMediaQuery } from "@mui/material";
import Navbar from "./components/navbar";
import ScrollToTop from "./context/ui/ScrollToTop";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Home from "./components/home/Home";
import MobileDrawer from "./components/drawer/MobileDrawer";
import StickyFooter from "./components/footer/Footer";
import Assistance from "./components/assistance/Assistance";
import Register from "./components/account/Register";
import Login from "./components/account/Login";
import RequireAuth from "./components/authentication/RequireAuth";
import AuthLanding from "./components/authentication/AuthLanding";
import Account from "./components/account/Account";
import Notifications from "./components/notifications/Notifications";
import PropertyPage from "./components/portfolio/property/PropertyPage";
import PageNotFound from "./components/authentication/PageNotFound";
import PartnershipPage from "./components/portfolio/partnership/PartnershipPage";
import AddPartnership from "./components/portfolio/partnership/AddPartnership";
import AddPremises from "./components/portfolio/premises/AddPremises";

import PremisesPage from "./components/portfolio/premises/PremisesPage";
import PropertyList from "./components/portfolio/property/PropertyList";
import AddProperty from "./components/portfolio/addProperty/AddProperty";
import FolioControl from "./components/portfolio/foliocontrol/FolioControl";
import Sidebar from "./components/portfolio/foliocontrol/components/Sidebar";
import Breadcrumbs from "./components/navbar/Breadcrumbs";
import ContactsPage from "./components/portfolio/contact/ContactsPage";

function App() {
  const { pathname } = useLocation();
  const excludedPaths = ["/home", "/assistance", "/register", "/login", "/"];
  const [isCollapsed, setIsCollapsed] = React.useState(false);
  const matches = useMediaQuery(theme.breakpoints.up("lg"));
  return (
    <ThemeProvider theme={theme}>
      <ContextWrapper>
        <CssBaseline />
        <Navbar />

        <ScrollToTop />

        <div className="app">
          {!excludedPaths.includes(pathname) && matches ? (
            <RequireAuth>
              <Sidebar
                isCollapsed={isCollapsed}
                setIsCollapsed={setIsCollapsed}
              />
              {isCollapsed && <Breadcrumbs true />}
            </RequireAuth>
          ) : (
            isCollapsed && <Breadcrumbs false />
          )}

          <main className="content">
            <Routes>
              {/*FOR NON AUTHENTICATED USERS:*/}
              <Route path="/" element={<Navigate replace to="/home" />} />
              <Route path="/home" element={<Home />} />
              <Route path="/assistance" element={<Assistance />} />
              <Route path="/register" element={<Register />} />
              <Route path="/login" element={<Login />} />
              {/*FOR AUTHENTICATED USERS:*/}
              <Route path="/authentication" element={<AuthLanding />} />
              <Route
                path="/partnership"
                element={<Navigate replace to="/account/partnership" />}
              />
              <Route
                path="/options"
                element={<Navigate replace to="/account/options" />}
              />
              {/*REDIRECT*/}
              <Route
                path="/FolioControl/properties/details"
                element={<Navigate replace to="/FolioControl/properties" />}
              />
              <Route
                path="/FolioControl/properties/premises"
                element={<Navigate replace to="/FolioControl/properties" />}
              />
              <Route
                path="/FolioControl/properties/photos"
                element={<Navigate replace to="/FolioControl/properties" />}
              />
              <Route
                path="/FolioControl/properties/documents"
                element={<Navigate replace to="/FolioControl/properties" />}
              />
              <Route
                path="/FolioControl/properties/premises/premise"
                element={<Navigate replace to="/FolioControl/properties" />}
              />
              <Route
                path="/account/partnership/:partnershipID"
                element={
                  <RequireAuth>
                    <PartnershipPage />
                  </RequireAuth>
                }
              />
              <Route
                path="/account"
                element={<Navigate replace to="/account/userInformation" />}
              />
              <Route
                path="/account/partnership"
                element={
                  <RequireAuth>
                    <Account />
                  </RequireAuth>
                }
              />
              <Route
                path="/account/userinformation"
                element={
                  <RequireAuth>
                    <Account />
                  </RequireAuth>
                }
              />
              <Route
                path="/account/options"
                element={
                  <RequireAuth>
                    <Account />
                  </RequireAuth>
                }
              />
              <Route
                path="/FolioControl/properties"
                element={
                  <RequireAuth>
                    <PropertyList />
                  </RequireAuth>
                }
              />
              <Route
                path="/FolioControl/properties/details/:propertyID"
                element={
                  <RequireAuth>
                    <PropertyPage />
                  </RequireAuth>
                }
              />
              <Route
                path="/FolioControl/properties/photos/:propertyID"
                element={
                  <RequireAuth>
                    <PropertyPage />
                  </RequireAuth>
                }
              />
              <Route
                path="/FolioControl/properties/documents/:propertyID"
                element={
                  <RequireAuth>
                    <PropertyPage />
                  </RequireAuth>
                }
              />
              <Route
                path="/FolioControl/properties/premises/:propertyID"
                element={
                  <RequireAuth>
                    <PropertyPage />
                  </RequireAuth>
                }
              />
              <Route
                path="/notifications"
                element={
                  <RequireAuth>
                    <Notifications />
                  </RequireAuth>
                }
              />
              <Route
                path="/FolioControl/properties/premises/premise/:premisesID"
                element={
                  <RequireAuth>
                    <PremisesPage />
                  </RequireAuth>
                }
              />
              <Route
                path="/FolioControl/properties/add"
                element={
                  <RequireAuth>
                    <AddProperty />
                  </RequireAuth>
                }
              />
              <Route
                path="/FolioControl"
                element={
                  <RequireAuth>
                    <FolioControl />
                  </RequireAuth>
                }
              />
              <Route
                path="/FolioControl/properties/addPremises/:propertyID"
                element={
                  <RequireAuth>
                    <AddPremises />
                  </RequireAuth>
                }
              />
              <Route
                path="/partnership/add"
                element={
                  <RequireAuth>
                    <AddPartnership />
                  </RequireAuth>
                }
              />
              {/*SIDE BAR*/}
              <Route
                path="/FolioControl/contacts"
                element={
                  <RequireAuth>
                    <ContactsPage />
                  </RequireAuth>
                }
              />
              {/*  All catcher*/} <Route path="*" element={<PageNotFound />} />
            </Routes>
          </main>
        </div>
        <StickyFooter />
        <MobileDrawer />
      </ContextWrapper>
    </ThemeProvider>
  );
}

export default App;
