import * as React from "react";
import { useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";

import {
  TextField,
  Typography,
  Link,
  Grid,
  Avatar,
  Paper,
  InputAdornment,
  IconButton,
  Input,
  InputLabel,
} from "@mui/material";

import { Box } from "@mui/system";
import { Lock, Visibility, VisibilityOff } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import IsEmail from "../validation/IsMail";
import { useAuthContext } from "../../context/AuthProvider";
import { useSnackbarContext } from "../../context/ui/SnackbarProvider";
import { Colors } from "../../styles/themes";

export default function Login() {
  // state
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = React.useState(false);

  // Password field
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  // context
  const { login } = useAuthContext();
  const { handleSnackbar } = useSnackbarContext();

  // for form control
  const [required, setRequired] = useState(false);
  const [badEmail, setBadEmail] = useState(false);

  // navigation
  const navigate = useNavigate();

  const handleLogin = async () => {
    setLoading(true);
    const user = {
      email,
      password,
    };
    if (!IsEmail(user.email)) {
      handleSnackbar({
        content: "Failed to login, not a valid email",
        severity: "error",
        duration: 3000,
      });
    } else if (user.email && user.password) {
      // await new Promise((resolve) => setTimeout(resolve, 500)); // SIMULATE NETWORK LATANCY
      try {
        await login(user);
        navigate("/account");
        handleSnackbar({
          content: `Welcome back ${user.name || user.email}`,
        });
      } catch (err) {
        handleSnackbar({
          content: "Check your credentials!",
          severity: "error",
          duration: 3000,
        });
      }
    } else {
      setRequired(true);
      handleSnackbar({
        content: "Something is wrong, report this to the developer!",
        severity: "error",
        duration: 3000,
      });
    }
    setLoading(false);
  };

  const handleChangeEmail = (event) => {
    if (required || badEmail) {
      setRequired(false);
      setBadEmail(false);
    } // Don't know if this is efficient
    setEmail(event.target.value);
  };
  const handleChangePassword = (event) => {
    if (required) {
      setRequired(false);
    }
    setPassword(event.target.value);
  };

  return (
    <Grid
      container
      component="main"
      sx={{
        height: "80vh",
        width: "100%",
      }}
    >
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage: "url(https://picsum.photos/700)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          borderRadius: "25px 0 0 25px",
          boxShadow: "10",
        }}
      />
      <Grid
        item
        xs={12}
        sm={8}
        md={5}
        sx={{ borderRadius: { sm: "0 25px 25px 0", xs: "25px" } }}
        component={Paper}
        elevation={6}
        square
      >
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <Lock sx={{ mx: "auto", color: Colors.secondary }} />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in!
          </Typography>
          <Box component="form" sx={{ mt: 1 }}>
            <TextField
              sx={{ mb: 2 }}
              margin="normal"
              required
              error={required || badEmail}
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={handleChangeEmail}
            />
            <InputLabel htmlFor="standard-adornment-password">
              Password
            </InputLabel>
            <Input
              id="password"
              type={showPassword ? "text" : "password"}
              fullWidth
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
              onChange={handleChangePassword}
            />

            <LoadingButton
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              fullWidth
              onClick={() => handleLogin()}
              loading={loading}
            >
              Log in
            </LoadingButton>
            <Grid container>
              {/* <Grid item xs>
                  <Link href="#" variant="body2">
                    Forgot password?
                  </Link>
                </Grid> */}
              <Grid item sx={{ ml: "auto" }}>
                <Link component={RouterLink} to="/register" variant="body2">
                  Don't have an account? Sign Up
                </Link>
              </Grid>
            </Grid>
            <Typography
              sx={{ mt: 5 }}
              variant="body2"
              color="text.secondary"
              align="center"
            >
              {"Copyright © "}
              <Link color="inherit" href="https://www.LexsIT.com">
                LexsIT
              </Link>{" "}
              {new Date().getFullYear()}.
            </Typography>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
