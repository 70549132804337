import { Container, Typography } from "@mui/material";
import LPaper from "../../styles/lPaper";
import { Link as RouterLink } from "react-router-dom";
export default function Notifications() {
  return (
    <Container>
      <LPaper>
        <Typography
          variant="h5"
          sx={{ textDecoration: "none", color: "inherit" }}
          component={RouterLink}
          to={"/portfolio"}
        >
          Notifications
        </Typography>
      </LPaper>
    </Container>
  );
}
