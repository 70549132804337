import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
} from "@mui/material";
import { PropertyTypes } from "../../../common";

export default function DetailsStep({
  propertyName,
  setPropertyName,
  propertyType,
  setPropertyType,
  street,
  setStreet,
  streetNumber,
  setStreetNumber,
  city,
  setCity,
  zipCode,
  setZipCode,
  country,
  setCountry,
  partnershipData,
  partnership,
  setPartnership,
  activePartnership,
  handleChange,
}) {
  return (
    <Grid container spacing={3} justifyContent="center">
      <Grid item xs={12} md={8} margin={5}>
        <Paper
          sx={{
            minWidth: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "2rem",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="property-name"
                label="Property Name"
                variant="outlined"
                value={propertyName}
                onChange={handleChange(setPropertyName)}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="outlined" required>
                <InputLabel htmlFor="property-type">Property Type</InputLabel>
                <Select
                  id="property-type"
                  value={propertyType}
                  onChange={(e) => setPropertyType(e.target.value)}
                  label="Property Type"
                  displayEmpty
                  variant="outlined"
                >
                  {PropertyTypes.map((type) => (
                    <MenuItem key={type} value={type.type}>
                      {type.icon}
                      {type.type}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="street"
                label="Street"
                variant="outlined"
                value={street}
                onChange={handleChange(setStreet)}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="street-number"
                label="Street Number"
                variant="outlined"
                value={streetNumber}
                onChange={handleChange(setStreetNumber)}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="city"
                label="City"
                variant="outlined"
                value={city}
                onChange={handleChange(setCity)}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="zip-code"
                label="Zip Code"
                variant="outlined"
                value={zipCode}
                onChange={handleChange(setZipCode)}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="country"
                label="Country"
                variant="outlined"
                value={country}
                onChange={handleChange(setCountry)}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="outlined" required>
                <InputLabel htmlFor="Partnership">Partnership</InputLabel>
                <Select
                  id="Partnership"
                  value={partnership || activePartnership.name} // Set activePartnership as the selected value
                  onChange={(e) => setPartnership(e.target.value)}
                  label="Partnership"
                  required
                >
                  <MenuItem value="">Select Partnership</MenuItem>
                  {partnershipData.map((element) => (
                    <MenuItem key={element.partnershipID} value={element.name}>
                      {element.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}
