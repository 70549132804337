import { Box, Container, IconButton, Tab, Tabs, Tooltip, Typography } from '@mui/material';

import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import usePartnership from '../../../api/partnership';
import { Link as RouterLink } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import HouseIcon from '@mui/icons-material/House';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import DescriptionIcon from '@mui/icons-material/Description';
import PartnershipDetails from './PartnershipDetails';
import PartnershipPhotos from './PartnershipPhotos';
import PartnershipDocuments from './PartnershipDocuments';
import { Person } from '@mui/icons-material';
import PartnershipMembers from './PartnershipMembers';
export default function PartnershipPage() {
  const { getPartnershipByID } = usePartnership();
  const [partnership, setPartnership] = useState(null);
  const { partnershipID } = useParams();
  const [activeTab, setActiveTab] = useState(0);
  useEffect(() => {
    async function fetchData() {
      try {
        const partnershipData = await getPartnershipByID(partnershipID);

        setPartnership(partnershipData);
      } catch (error) {
        console.error('Error fetching property:', error);
      }
    }
    fetchData();
  }, [partnershipID, getPartnershipByID]);

  return (
    <Container sx={{ py: '2rem', mt: '50px' }}>
      <Box display='flex' alignItems='center' mb={3}>
        <IconButton component={RouterLink} to='/account/partnership' color='primary'>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant='h4' gutterBottom>
          {partnership?.name}
        </Typography>
        <Tooltip title='Delete Partnership'>
          <IconButton
            onClick={() => {
              console.log('todo');
            }}
            sx={{ marginLeft: 'auto' }}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Box>

      <Tabs value={activeTab} onChange={(event, newValue) => setActiveTab(newValue)} centered>
        <Tab label='Partnership Details' icon={<HouseIcon />} />
        <Tab label='Members' icon={<Person />} />
        <Tab label='Photos' icon={<PhotoLibraryIcon />} />
        <Tab label='Documents' icon={<DescriptionIcon />} />
      </Tabs>

      {partnership && (
        <>
          {activeTab === 0 && <PartnershipDetails partnership={partnership} />}
          {activeTab === 1 && <PartnershipMembers partnership={partnership} />}
          {activeTab === 2 && <PartnershipPhotos partnership={partnership} />}
          {activeTab === 3 && <PartnershipDocuments partnership={partnership} />}
        </>
      )}
    </Container>
  );
}
