import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Container } from '@mui/system';
import { Button, Typography } from '@mui/material';
import Banner from './Banner';
import Promotions from './Promotions';
import JPaper from '../../styles/jPaper';
import AboutUs from './Aboutus';
import Features from './Features';
import HowItWorks from './HowItWorks';
import { ContainerReveal, GridReveal } from '../../styles/containerReveal';

export default function Home() {
  function reveal() {
    var reveals = document.querySelectorAll('.reveal');

    for (var i = 0; i < reveals.length; i++) {
      var windowHeight = window.innerHeight;
      var elementTop = reveals[i].getBoundingClientRect().top;
      var elementVisible = 1;

      if (elementTop < windowHeight - elementVisible) {
        reveals[i].classList.add('active');
      } else {
        reveals[i].classList.remove('active');
      }
    }
  }

  window.addEventListener('scroll', reveal);

  return (
    <Container sx={{ minWidth: '100%' }}>
      <Banner />
      <Promotions />
      <ContainerReveal className='reveal' id='HowItWorks' sx={{ my: '30px', minWidth: '100%' }}>
        <HowItWorks />
      </ContainerReveal>
      <ContainerReveal className='reveal' id='Features' sx={{ my: '60px' }}>
        <Features />
        <Button sx={{ minWidth: '100%' }} variant='contained' component={RouterLink} to='/register'>
          Create your account now!
        </Button>
      </ContainerReveal>

      <JPaper id='AboutUsPaper' elevation={12} sx={{ mt: '230px' }}>
        <Typography variant='h2' color={'secondary'}>
          About us
        </Typography>
      </JPaper>
      <GridReveal container className='reveal' justifyContent='center'>
        <AboutUs />
      </GridReveal>
    </Container>
  );
}
