import React from 'react';
import { Button, Box, Typography, Paper } from '@mui/material';
import { Navigate, Link as RouterLink } from 'react-router-dom';
import LockIcon from '@mui/icons-material/Lock';
import { useAuthContext } from '../../context/AuthProvider';
export default function AuthLanding() {
  const { isAuth } = useAuthContext();

  if (!isAuth) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '80vh', // Adjust the height as needed
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Paper
          sx={{
            padding: '20px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '20px',
          }}
        >
          {/* Use the LockIcon */}
          <LockIcon sx={{ fontSize: '72px', color: 'primary' }} />

          <Typography variant='h4'>Login Required</Typography>
          <Typography variant='body1'>You need to login to access this page!</Typography>

          <Button component={RouterLink} to='/login' variant='contained' color='primary'>
            Go to Login
          </Button>
        </Paper>
      </Box>
    );
  }
  return <Navigate to='/account' />;
}
