import { Copyright } from "@mui/icons-material";
import { Box, Grid, Link, List, ListItemText, Typography } from "@mui/material";

import React from "react";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import { useNavigate } from "react-router-dom";

import { Colors } from "../../styles/themes";
import FooterTitle from "../../styles/footer";

export default function StickyFooter() {
  const navigate = useNavigate();

  function HandleAboutClick() {
    navigate("/home");
    setTimeout(() => {
      const aboutUsPaper = document.getElementById("AboutUsPaper");
      aboutUsPaper.scrollIntoView(
        {
          behavior: "smooth",
        },
        true
      );
    }, 300);
  }
  return (
    <>
      <Box
        sx={{
          background: Colors.bottom,

          color: Colors.white,
          p: { xs: 4, md: 4 },
          pt: 6,
          pb: 6,
          fontSize: { xs: "12px", md: "14px" },
        }}
      >
        <Link variant="h4" href="/home" sx={{ color: "inherit" }}>
          FolioControl
        </Link>

        <Grid container spacing={2}>
          <Grid item md={6} lg={4}>
            <FooterTitle variant="body1">About us!</FooterTitle>
            <Typography variant="caption2">
              Our platform provides a seamless and user-friendly experience,
              allowing you to effortlessly take control of your diverse
              portfolio and efficiently handle all those time-consuming business
              tasks that tend to pile up. With our intuitive interface, you can
              easily navigate through your investments, assets, and projects,
              ensuring a clear overview and informed decision-making. Say
              goodbye to the hassle of managing multiple accounts, sifting
              through piles of paperwork, and juggling various business errands.
              Our solution streamlines and automates these processes, saving you
              valuable time and energy. Whether it's tracking financial
              performance, monitoring market trends, or handling administrative
              chores, our platform empowers you to stay on top of your business
              with ease.
            </Typography>
            <Box
              sx={{
                mt: 4,
                color: Colors.dove_gray,
              }}
            >
              <Link
                sx={{
                  color: "inherit",
                  mr: 1,
                  "&:hover": {
                    color: Colors.primary,
                    cursor: "pointer",
                  },
                }}
                href="https://www.facebook.com/"
              >
                <FacebookIcon />
              </Link>
              <Link
                sx={{
                  color: "inherit",
                  mr: 1,
                  "&:hover": {
                    color: Colors.primary,
                    cursor: "pointer",
                  },
                }}
                href="https://twitter.com/"
              >
                <TwitterIcon />
              </Link>
              <Link
                sx={{
                  color: "inherit",
                  "&:hover": {
                    color: Colors.primary,
                    cursor: "pointer",
                  },
                }}
                href="https://www.instagram.com/"
              >
                {" "}
                <InstagramIcon />
              </Link>
            </Box>
          </Grid>
          <Grid item md={6} lg={2}>
            <FooterTitle variant="body1">Information</FooterTitle>
            <List>
              <ListItemText>
                <Typography
                  sx={{
                    "&:hover": {
                      textDecoration: "underline",
                      cursor: "pointer",
                    },
                  }}
                  lineHeight={2}
                  variant="caption2"
                  onClick={() => HandleAboutClick()}
                >
                  Assistance
                </Typography>
              </ListItemText>
              <ListItemText>
                <Typography
                  sx={{
                    "&:hover": {
                      textDecoration: "underline",
                      cursor: "pointer",
                    },
                  }}
                  lineHeight={2}
                  variant="caption2"
                  onClick={() => HandleAboutClick()}
                >
                  About us
                </Typography>
              </ListItemText>
              <ListItemText>
                <Typography
                  sx={{
                    "&:hover": {
                      textDecoration: "underline",
                      cursor: "pointer",
                    },
                  }}
                  lineHeight={2}
                  variant="caption2"
                  onClick={() => HandleAboutClick()}
                >
                  Privacy policy
                </Typography>
              </ListItemText>
              <ListItemText>
                <Typography
                  lineHeight={2}
                  variant="caption2"
                  onClick={() => HandleAboutClick()}
                >
                  Terms & conditions
                </Typography>
              </ListItemText>
            </List>
          </Grid>
          <Grid item md={6} lg={2}>
            <FooterTitle variant="body1">My Account</FooterTitle>
            <List>
              <ListItemText>
                <Typography
                  sx={{
                    "&:hover": {
                      cursor: "pointer",
                      textDecoration: "underline",
                    },
                  }}
                  lineHeight={2}
                  variant="caption2"
                  onClick={() => navigate("/authentication")}
                >
                  Login
                </Typography>
              </ListItemText>
              <ListItemText>
                <Typography
                  sx={{
                    "&:hover": {
                      cursor: "pointer",
                      textDecoration: "underline",
                    },
                  }}
                  lineHeight={2}
                  variant="caption2"
                  onClick={() => navigate("/portfolio")}
                >
                  My Portfolio
                </Typography>
              </ListItemText>
              <ListItemText>
                <Typography
                  sx={{
                    "&:hover": {
                      cursor: "pointer",
                      textDecoration: "underline",
                    },
                  }}
                  lineHeight={2}
                  variant="caption2"
                  onClick={() => navigate("/account")}
                >
                  My Account
                </Typography>
              </ListItemText>
              <ListItemText>
                <Typography
                  sx={{
                    "&:hover": {
                      cursor: "pointer",
                      textDecoration: "underline",
                    },
                  }}
                  lineHeight={2}
                  variant="caption2"
                  onClick={() => navigate("/notifications")}
                >
                  Notifications
                </Typography>
              </ListItemText>
            </List>
          </Grid>
        </Grid>
      </Box>

      <Box
        sx={{
          backgroundColor: Colors.white,
        }}
      >
        <Typography
          variant="body1"
          sx={{
            width: "100%",
            textAlign: "center",
            padding: { lg: "30px", sm: "15px" },
            color: "black",
          }}
        >
          <Copyright fontSize="sm" />
          2023 LexsIT{" "}
          <img
            src={process.env.PUBLIC_URL + "/LexsIT_Logo_Trans(black).png"}
            alt="Icon"
            style={{
              width: "24px",
              height: "24px",
              marginRight: "8px",
              marginLeft: "8px",
            }}
          />
        </Typography>
      </Box>
    </>
  );
}
