import React from 'react';
import { Typography, Paper, Box, Grid, Container } from '@mui/material';

import PhotoUploadComponent from '../PhotoUploadComponent';
import { useSnackbarContext } from '../../../context/ui/SnackbarProvider';
import { useAuthContext } from '../../../context/AuthProvider';
import useProperty from '../../../api/property';
const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  paper: {
    padding: '1rem',
    width: '100%',
  },
};

export default function PropertyPhotos({ property, setSave }) {
  const imageUrl = process.env.REACT_APP_PROPERTYPHOTOS_URL;
  const { verify } = useAuthContext();
  const { handleSnackbar } = useSnackbarContext();
  const { updateProperty } = useProperty();

  const handleUploadPhoto = async (photo) => {
    const formattedDocument = {
      propertyID: property.propertyID,
      propertyName: property.propertyName,
      propertyType: property.propertyType,
      propertySize: property.propertySize,
      propertyPrice: property.propertyPrice,
      propertyImage: photo,
      propertyImg: property.propertyImg,
      propertyDescription: property.propertyDescription,
      street: property.street,
      streetNumber: property.streetNumber,
      city: property.city,
      zipCode: property.zipCode,
      country: property.country,
      FK_partnershipID: property.FK_partnershipID,
    };
    await updateProperty(formattedDocument);
    await verify();
    setSave(true);
    handleSnackbar({
      content: 'Successfully updated property photo!',
    });
  };
  return (
    <Container maxWidth='lg'>
      <Box sx={styles.container}>
        <Paper elevation={3} sx={styles.paper}>
          <Typography variant='h5' gutterBottom>
            Property Photos
          </Typography>
          <Grid container spacing={2}>
            {property.propertyImg !== "null" ? (
              <Grid item xs={12} align='center'>
                <img
                  srcSet={`${imageUrl}/${property.propertyImg}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                  src={`${imageUrl}/${property.propertyImg}?w=164&h=164&fit=crop&auto=format`}
                  alt={property.name}
                  loading='lazy'
                  width={'30%'}
                />
              </Grid>
            ) : (
              // Default image or message when there are no property images
              <Grid item xs={12} align='center'>
                <img src={process.env.PUBLIC_URL + '/_noimage.jpg?w=164&h=164&fit=crop&auto=format&dpr=2 2x'} alt='Property' width={'30%'} loading='lazy' />
              </Grid>
            )}
          </Grid>
        </Paper>
        <PhotoUploadComponent handleUploadPhoto={handleUploadPhoto} />
      </Box>
    </Container>
  );
}
