import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ImageIcon from "@mui/icons-material/Image";
import { useEffect } from "react";
export default function PhotoStep({
  handleFileChange,
  propertyImage,
  previewImage,
  setPreviewImage,
}) {
  useEffect(() => {
    if (propertyImage) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(propertyImage);
    } else {
      setPreviewImage(null);
    }
  }, [propertyImage]);
  return (
    <Grid container spacing={3} justifyContent="center">
      <Grid item xs={12} md={8} margin={5}>
        <Paper
          sx={{
            minWidth: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "2rem",
          }}
        >
          {previewImage && (
            <Box sx={{ mt: 2 }}>
              <img
                src={previewImage}
                alt="Selected Preview"
                style={{ maxWidth: "100%", maxHeight: "200px" }}
              />
            </Box>
          )}
          {propertyImage && (
            <Typography variant="body1" sx={{ mt: 2 }}>
              <ImageIcon sx={{ mr: 1 }} />
              Selected photo: {propertyImage.name}
            </Typography>
          )}
          <label htmlFor="photo-upload">
            <input
              type="file"
              id="photo-upload"
              style={{ display: "none" }}
              accept="image/jpeg, image/png, image/jpg"
              onChange={handleFileChange}
            />
            <Button
              variant="outlined"
              color="primary"
              component="span"
              startIcon={<CloudUploadIcon />}
              sx={{ mb: 2 }}
            >
              Select Photo
            </Button>
          </label>
        </Paper>
      </Grid>
    </Grid>
  );
}
