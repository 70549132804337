import React, { useEffect, useState } from 'react';
import { Typography, Paper, Box, Container, Skeleton } from '@mui/material';
import DocumentUploadComponent from '../DocumentUploadComponent';
import usePropertyDocument from '../../../api/propertyDocument';
import { useSnackbarContext } from '../../../context/ui/SnackbarProvider';
import { useAuthContext } from '../../../context/AuthProvider';
import CloseIcon from '@mui/icons-material/Close';
import { Colors } from '../../../styles/themes';
import { useDialogContext } from '../../../context/ui/DialogProvider';
import WarningIcon from '@mui/icons-material/Warning';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  paper: {
    padding: '1rem',
    width: '100%',
  },
};

export default function PropertyDocuments({ property }) {
  const { handleDialog } = useDialogContext();

  const { getDocumentsByPropertyID, deleteDocumentByID, createDocument } = usePropertyDocument();
  const [loading, setLoading] = useState(true);
  const [documents, setDocuments] = useState([]);
  const { handleSnackbar } = useSnackbarContext();
  const { verify } = useAuthContext();
  const baseURL = process.env.REACT_APP_PROPERTYDOCUMENTS_URL;

  const handleUploadDocument = async (document) => {
    const formattedDocument = {
      ...document,
      propertyId: property.propertyID,
    };

    await createDocument(formattedDocument);
    await verify();
    handleSnackbar({
      content: 'Successfully created property',
    });
    fetchData(property.propertyID);
  };
  useEffect(() => {
    fetchData(property.propertyID);
  }, [property]);

  const fetchData = async (propertyID) => {
    const data = await getDocumentsByPropertyID(propertyID);
    setDocuments(data);
    setLoading(false);
  };

  function getDaysUntilExpiry(expiryDate) {
    const today = new Date();
    const expirationDate = new Date(expiryDate);
    const timeDifference = expirationDate - today;
    return Math.ceil(timeDifference / (1000 * 3600 * 24));
  }

  const handleSnackbarDocumentDelete = async (documentID) => {
    await deleteDocumentByID(documentID);
    fetchData(property.propertyID);
  };

  const deleteDocumentDialog = (document) => {
    handleDialog({
      title: 'Delete document',
      content: 'Confirmation',
      moreInfo: `Are you sure you want to delete ${document.name}`,
      confirmText: 'DELETE DOCUMENT',
      ifErrorText: `Failed to delete document`,
      ifSuccessText: 'Successfully deleted document!',
      onConfirm: () => {
        handleSnackbarDocumentDelete(document.propertyDocumentID);
      },
    });
  };

  const documentTypes = ['AKTE', 'JAARLIJKSE AFREKENING', 'AFSCHRIFT', 'EIGENDOMSAKTE', 'UITTREKSEL', 'BODEMATTEST'];
  const documentsByType = {};
  documents?.forEach((document) => {
    const type = document.documentType;
    if (!documentsByType[type]) {
      documentsByType[type] = [];
    }
    documentsByType[type].push(document);
  });

  return (
    <Container maxWidth='lg'>
      <Box sx={styles.container}>
        <Paper elevation={3} sx={styles.paper}>
          <Typography variant='h5' gutterBottom>
            Property Documents
          </Typography>

          {documentTypes.map((type, index) => (
            <Box key={index}>
              <Typography variant='h6'>{type}</Typography>
              {loading ? (
                <>
                  <Skeleton />
                </>
              ) : documentsByType[type] && documentsByType[type].length > 0 ? (
                <Box>
                  {documentsByType[type].map((document, docIndex) => {
                    const daysUntilExpiry = getDaysUntilExpiry(document.expiryDate);

                    return (
                      <>
                        <Box key={docIndex} sx={{ display: 'flex', alignItems: 'center' }}>
                          <a href={`${baseURL}/${document.documentName}`} target='_blank' rel='noopener noreferrer'>
                            <Typography variant='body1'>
                              {document.name} - {document.expiryDate}
                            </Typography>
                          </a>

                          <CloseIcon
                            sx={{
                              ':hover': {
                                color: Colors.secondary,
                                cursor: 'pointer',
                              },
                            }}
                            onClick={() => {
                              deleteDocumentDialog(document);
                            }}
                          />
                        </Box>
                        {daysUntilExpiry < 30 && (
                          <Typography variant='body1' color={Colors.danger}>
                            <WarningIcon /> {daysUntilExpiry} days until expiry
                          </Typography>
                        )}
                      </>
                    );
                  })}
                </Box>
              ) : (
                <Typography variant='body1' color={'red'}>
                  {type} documents missing for this property.
                </Typography>
              )}
            </Box>
          ))}
        </Paper>
        <DocumentUploadComponent handleUploadDocument={handleUploadDocument} />
      </Box>
    </Container>
  );
}
