import HomeIcon from "@mui/icons-material/Home";

import React from "react";
import {
  Button,
  Chip,
  Tooltip,
  Typography,
  emphasize,
  styled,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router";

import { Breadcrumb } from "../../styles/navbar";
import { Colors } from "../../styles/themes";

export default function Breadcrumbs() {
  const navigate = useNavigate();
  function handleClick(name) {
    navigate(`/${name}`);
  }

  const StyledBreadcrumb = styled(Chip)(({ theme }) => {
    const backgroundColor = Colors.secondary;
    return {
      backgroundColor,
      height: theme.spacing(3),
      color: Colors.primary,
      fontWeight: theme.typography.fontWeightRegular,
      "&:hover, &:focus": {
        backgroundColor: emphasize(backgroundColor, 0.06),
      },
      "&:active": {
        boxShadow: theme.shadows[1],
        backgroundColor: emphasize(backgroundColor, 0.12),
      },
    };
  });

  const { pathname } = useLocation();
  const pathnames = pathname.split("/").filter((x) => x);
  return (
    <div role="presentation">
      <Breadcrumb
        aria-label="breadcrumb"
        sx={{ marginLeft: "10px", marginTop: "3px" }}
        separator={
          <Typography sx={{ color: Colors.secondary }}>{">"}</Typography>
        }
      >
        <Tooltip title={`Go home`} arrow>
          <StyledBreadcrumb
            onClick={() => handleClick("home")}
            variant="text"
            underline="hover"
            label="Home"
            icon={<HomeIcon fontSize="medium" color={Colors.primary} />}
          />
        </Tooltip>

        {pathnames.map((name, index) => {
          if (name !== "home") {
            const isLast = index === pathnames.length - 1;
            const tooltipTitle = pathnames.slice(0, index + 1).join("/");
            return isLast ? (
              <Tooltip key={name} title={`Current`} arrow>
                <span>
                  <Button disabled key={name}>
                    {name}
                  </Button>
                </span>
              </Tooltip>
            ) : (
              <Tooltip key={name} title={`Go to ${tooltipTitle}`} arrow>
                <Button
                  key={name}
                  underline="hover"
                  onClick={() => handleClick(tooltipTitle)}
                >
                  {name}
                </Button>
              </Tooltip>
            );
          }
          return null;
        })}
      </Breadcrumb>
    </div>
  );
}
