import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Colors } from "../../../../styles/themes";
import { Box, Fade } from "@mui/material";

export default function FolioTable({ rows, columns }) {
  return (
    <Fade in={true}>
      <Box
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
            color: Colors.white,
            backgroundColor: Colors.dark,
          },
          "& .name-column--cell": {
            color: Colors.secondary,
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: Colors.secondary,
            borderBottom: "none",
            color: Colors.primary,
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: Colors.dark,
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: Colors.secondary,
            color: Colors.primary,
          },
          "& .MuiCheckbox-root": {
            color: `${Colors.primary} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${Colors.dark} !important`,
          },
          "& .MuiTablePagination-input, & .MuiTablePagination-caption, & .MuiTablePagination-selectLabel, & .MuiTablePagination-displayedRows":
            {
              color: `${Colors.primary} !important`, // Set the background color for the select box
            },
        }}
      >
        <DataGrid
          rows={rows}
          columns={columns}
          height="75vh"
          components={{ Toolbar: GridToolbar }}
        />
      </Box>
    </Fade>
  );
}
