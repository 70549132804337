import axios from 'axios';
import { useCallback, useMemo } from 'react';

const baseUrl = `${process.env.REACT_APP_API_URL}/propertyDocument`;

const usePropertyDocument = () => {
  //document options
  //create document
  const createDocument = useCallback(async (document) => {
    await axios({
      method: 'POST',
      url: `${baseUrl}/create`,
      headers: {
        Authorization: localStorage.getItem('token'),
        'Content-Type': 'multipart/form-data',
      },
      data: document,
    });
  }, []);
  //getDocuments by propertyId
  const getDocumentsByPropertyID = useCallback(async (documentID) => {
    console.log(documentID);
    const documents = await axios({
      method: 'GET',
      url: `${baseUrl}/${documentID}`,
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });
    return documents.data;
  }, []);

  const deleteDocumentByID = useCallback(async (documentID) => {
    await axios({
      method: 'DELETE',
      url: `${baseUrl}/delete/${documentID}`,
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });
  }, []);

  const propertyDocumentApi = useMemo(
    () => ({
      createDocument,
      getDocumentsByPropertyID,
      deleteDocumentByID,
    }),
    [createDocument, getDocumentsByPropertyID, deleteDocumentByID],
  );
  return propertyDocumentApi;
};

export default usePropertyDocument;
