import { UIProvider } from './ui/UIContext';
import AuthProvider from './AuthProvider';
import { SnackbarProvider } from './ui/SnackbarProvider';
import DialogProvider from './ui/DialogProvider';
export default function ContextWrapper({ children }) {
  return (
    <UIProvider>
      <SnackbarProvider>
        <DialogProvider>
          <AuthProvider>{children}</AuthProvider>
        </DialogProvider>
      </SnackbarProvider>
    </UIProvider>
  );
}
