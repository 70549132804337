import { useTheme } from '@emotion/react';
import { useMediaQuery } from '@mui/material';
import NavbarMobile from './NavbarMobile';
import NavbarDesktop from './NavbarDesktop';

export default function Navbar() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('lg'));
  return <>{matches ? <NavbarMobile matches={matches} /> : <NavbarDesktop matches={matches} />}</>;
}
