import { Button, Container, Grid, Typography, Link as Linker, AccordionDetails, AccordionSummary, Accordion, ListItem, List, ListItemText, ListItemIcon } from '@mui/material';
import React from 'react';

import JPaper from '../../styles/jPaper';
import LPaper from '../../styles/lPaper';
import { Link } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PhoneIcon from '@mui/icons-material/Phone';
import { LocationCity, Mail } from '@mui/icons-material';
import { Colors } from '../../styles/themes';
export default function Assistance() {
  return (
    <Container sx={{ mb: '50px' }}>
      <LPaper>
        <Typography variant='h4'>Assistance</Typography>
      </LPaper>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <JPaper sx={{ minWidth: '100%' }}>
            <Typography variant='h5'>I am a customer</Typography>
          </JPaper>
          <LPaper sx={{ backgroundColor: Colors.tertiary }}>
            <Typography variant='p'>Would you like more information about our offerings or services? Fill out the contact form and we will get back to you.</Typography>
            <ListItem>
              <Button sx={{ mt: '20px' }} component={Link} to='/formNotCustomer' variant='contained'>
                Fill out contact form
              </Button>
            </ListItem>
          </LPaper>
        </Grid>
        <Grid item xs={12} md={6}>
          <JPaper sx={{ minWidth: '100%' }}>
            <Typography variant='h5'>I am a customer</Typography>
          </JPaper>
          <LPaper sx={{ backgroundColor: Colors.tertiary }}>
            <Typography variant='p'>Do you have a specific question or suggestion? Let us know and we will answer your question as soon as possible.</Typography>
            <ListItem>
              <Button sx={{ mt: '20px' }} component={Link} to='/formCustomer' variant='contained'>
                Fill out a contact form!
              </Button>
            </ListItem>
          </LPaper>
        </Grid>
        <Grid item xs={0} md={3} lg={3} />
        <Grid item xs={12} md={6} lg={6}>
          <JPaper sx={{ minWidth: '100%', padding: '50px' }}>
            <Typography variant='h5'>Perhaps your question was already answered</Typography>
            <Typography variant='p'>
              Please refer to our{' '}
              <Linker
                onClick={() => {
                  window.scrollTo({
                    top: 800,
                    left: 10,
                    behavior: 'smooth',
                  });
                }}
                color='secondary'
              >
                Frequently Asked Questions
              </Linker>
            </Typography>
          </JPaper>
        </Grid>
        <Grid item lg={3} md={3} xs={0} />
        <Grid item xs={12} md={6}>
          <LPaper>
            <Typography sx={{ p: '10px', minWidth: '50%' }} variant='h5'>
              Questions & Answers
            </Typography>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1a-content' id='panel1a-header'>
                <Typography>Why can't I complete my order?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant='p'>
                  Check the following items:
                  <ListItem>1. Are you logged in? If you don't have a login yet, you need to register first.</ListItem>
                  <ListItem>2. Have you already confirmed the proposed delivery date or chosen another date? You can see this date at the top of the overview page of your shopping cart.</ListItem>
                  <ListItem>3. Are there products in your shopping basket for which it is indicated that there is no or insufficient stock? Adjust your order quantities if necessary.</ListItem>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1a-content' id='panel1a-header'>
                <Typography>How can I edit my portfolio?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant='p'>You can edit your portfolio by checking out the portfolio page and by pressing the pencil!</Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1a-content' id='panel1a-header'>
                <Typography>How and when do I pay?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant='p'>
                  With a positive credit score, you pay by invoice. The payment term and credit limit are also determined based on this score. Otherwise you pay to our delivery person upon delivery
                  via Bancontact, Visa or Mastercard.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </LPaper>
        </Grid>
        <Grid item xs={12} md={6}>
          <JPaper sx={{ minWidth: '100%' }}>
            <Typography variant='h5'>Contact Us</Typography>
            <List>
              <ListItem>
                <ListItemIcon>
                  <PhoneIcon />
                </ListItemIcon>
                <ListItemText primary='Call + 32 485 88 77 11' />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Mail />
                </ListItemIcon>
                <ListItemText
                  primary='Mail us at lexsIT@gmail.com'
                  onClick={() => {
                    window.location.href = 'mailto:alexander.lexsIT@gmail.com';
                  }}
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <LocationCity />
                </ListItemIcon>
                <ListItemText primary='LexsIT ' secondary='Blue Tower 1, Sluisweg 1, 9000 Gent' />
              </ListItem>
            </List>
          </JPaper>
        </Grid>
      </Grid>
    </Container>
  );
}
