import { Button, Container, Grid, Typography, useMediaQuery } from '@mui/material';
import theme from '../../styles/themes';

export default function HowItWorks() {
  const isMdScreen = useMediaQuery(theme.breakpoints.down('md'));
  const itemData = [
    {
      img: 'https://images.unsplash.com/photo-1484154218962-a197022b5858',
      title: 'Manage Rentals',
      text: `Easily manage your rental properties within FolioControl. You can input and update detailed information about your rented properties, including location, monthly rent, property description,
            and tenant details, if occupied. For active properties, you can also add contact information for your tenants or their designated representatives.`,
    },
    {
      img: 'https://images.unsplash.com/photo-1629371997433-d11e6161a8b3',
      title: 'Tenant Management',
      text: `Efficiently manage your tenants' information through the app. Keep track of lease agreements, lease terms, and contact details for effective communication. With FolioControl, you can easily
      access tenant-related data when needed, ensuring smooth and responsive tenant interactions.`,
    },
    {
      img: 'https://images.unsplash.com/photo-1522199755839-a2bacb67c546',
      title: 'Portfolio Management',
      text: `Take control of your entire real estate portfolio effortlessly. FolioControl enables you to view a complete overview of all your properties, their status, and key financial metrics. Stay
      informed about the performance of each property, making informed decisions to enhance your portfolio's overall profitability.`,
    },
  ];
  return (
    <Grid container>
      {itemData.map((item) => (
        <Grid item key={item.title} sm={12} lg={4} md={6} textAlign='center'>
          <img
            src={`${item.img}?w=366&fit=crop&auto=format`}
            srcSet={`${item.img}?w=366&fit=crop&auto=format&dpr=2 2x`}
            alt={item.title}
            loading='lazy'
            style={{ height: '250px', width: '90%', objectFit: 'cover', borderRadius: '10px' }}
          />
          <Typography variant='h4'>{item.title}</Typography>
          <Container style={{ minWidth: '100%', padding: '15px', fontSize: '1.15rem', height: isMdScreen ? '200px' : 'fit-content', textAlign: 'justify' }}>{item.text}</Container>

          <Button variant='contained'>Check it out !</Button>
        </Grid>
      ))}
    </Grid>
  );
}
