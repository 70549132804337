import { useState } from "react";
import usePremises from "../../../api/premises";
import { useSnackbarContext } from "../../../context/ui/SnackbarProvider";
import { useNavigate, useParams } from "react-router-dom";
import { useAuthContext } from "../../../context/AuthProvider";
import {
  Box,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link as RouterLink } from "react-router-dom";

export default function AddPremises() {
  //Get the id out of the navbar id
  const { propertyID } = useParams();
  const premisesApi = usePremises();
  const { handleSnackbar } = useSnackbarContext();
  const [loadingButton, setLoadingButton] = useState(false);
  const [premisesName, setPremisesName] = useState("");
  const [bus, setBus] = useState("");
  const [rent, setRent] = useState("");
  const [description, setDescription] = useState("");
  const [address, setAddress] = useState("");
  const [propertyRented, setPropertyRented] = useState(false);
  const [propertyActive, setPropertyActive] = useState(false);

  const [isActive, setIsActive] = useState(false);
  const [isRented, setIsRented] = useState(false);
  const [tenant, setTenant] = useState("");

  const navigate = useNavigate();
  const { verify } = useAuthContext();

  const handleChange = (setter) => (event) => {
    setter(event.target.value);
  };
  // Function to handle changes in the 'isActive' checkbox
  const handleIsActiveChange = (event) => {
    setIsActive(event.target.checked);
    setIsRented(event.target.checked);
    setPropertyActive(!propertyActive);
    setPropertyRented(!propertyRented);
    if (!propertyActive === false) {
      setPropertyRented(false);
    }
  };

  // Function to handle changes in the 'isRented' checkbox
  const handleIsRentedChange = (event) => {
    setIsRented(event.target.checked);
    setPropertyRented(!propertyRented);
  };

  async function handleRegisterPremise(formattedPremise) {
    console.log(formattedPremise);
    try {
      await premisesApi.createPremise(formattedPremise);
      await verify();
      handleSnackbar({
        content: "Successfully created premise",
      });

      setLoadingButton(false);
    } catch (e) {
      setLoadingButton(false);
      handleSnackbar({
        content: e.message,
        severity: "error",
      });
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoadingButton(true);
    const formattedPremise = {
      premisesName,
      bus,
      rent,
      description,
      address,
      propertyActive: propertyActive ? 1 : 0,
      propertyRented: propertyRented ? 1 : 0,
      tenant,
      FK_propertyID: propertyID,
    };

    handleRegisterPremise(formattedPremise);
    // redirect to portfolio
    navigate(`/FolioControl/properties/premises/${propertyID}`);
  };

  return (
    <Container sx={{ pt: "3.3rem", textAlign: "center" }}>
      <Box display="flex" alignItems="center" mb={3}>
        <Box>
          <IconButton
            component={RouterLink}
            to={`/FolioControl/properties/premises/${propertyID}`}
            color="primary"
          >
            <ArrowBackIcon />
          </IconButton>
        </Box>
        <Typography variant="h4" gutterBottom>
          Add Premises
        </Typography>
      </Box>
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12} md={8}>
          <Paper
            sx={{
              minWidth: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "2rem",
            }}
          >
            <form onSubmit={handleSubmit} style={{ width: "100%" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    id="premises-name"
                    label="Premises Name"
                    variant="outlined"
                    onChange={handleChange(setPremisesName)}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    id="bus"
                    label="Bus"
                    variant="outlined"
                    onChange={handleChange(setBus)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    id="rent"
                    label="Rent"
                    variant="outlined"
                    onChange={handleChange(setRent)}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    id="address"
                    label="Address"
                    variant="outlined"
                    onChange={handleChange(setAddress)}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="description"
                    label="Description"
                    variant="outlined"
                    onChange={handleChange(setDescription)}
                    required
                    multiline
                    rows={4}
                    sx={{ marginBottom: "1rem" }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isActive}
                        onChange={handleIsActiveChange}
                      />
                    }
                    label="Is Active"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControlLabel
                    disabled={!isActive}
                    control={
                      <Checkbox
                        checked={isRented}
                        onChange={handleIsRentedChange}
                      />
                    }
                    label="Is Rented"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    disabled={!isRented}
                    id="tenant"
                    label="Tenant"
                    variant="outlined"
                    onChange={handleChange(setTenant)}
                  />
                </Grid>
              </Grid>
              <LoadingButton
                variant="contained"
                sx={{ mt: "2rem" }}
                type="submit"
                loading={loadingButton}
                fullWidth
              >
                Add Premises
              </LoadingButton>
            </form>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}
