import React from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Tooltip,
  ListItemIcon,
  ListItemButton,
  Divider,
  Typography,
} from "@mui/material";
import { Login as LoginIcon } from "@mui/icons-material";

import { useAuthContext } from "../../context/AuthProvider";
import { Colors } from "../../styles/themes";
import UserAccountMenu from "../navbar/1_UserAccountButtons";
import PartnershipsMenu from "../navbar/2_PartnershipsMenu";

export default function AuthButton({ matches }) {
  const { isAuth } = useAuthContext();
  const activeLinkStyle = {
    color: Colors.white,
    backgroundColor: Colors.bottom,
    borderRadius: "2rem",
  };
  if (isAuth) {
    return (
      <>
        {/* User Account Menu */}
        <UserAccountMenu matches={matches} />

        <Divider orientation="vertical" flexItem />

        {/* Partnerships Menu */}
        <PartnershipsMenu matches={matches} />
      </>
    );
  }

  return (
    // Login Button
    <Tooltip title="Log in">
      <ListItemButton
        sx={{
          justifyContent: "center",
        }}
        value="/login"
        component={RouterLink}
        to="/login"
      >
        <ListItemIcon
          sx={{
            display: "flex",
            justifyContent: "center",
            fontSize: "2rem",
            color: Colors.primary,
            ":hover": {
              color: Colors.white,
              backgroundColor: Colors.bottom,
              borderRadius: "2rem",
            },
            ...(window.location.pathname === "/login" ? activeLinkStyle : {}),
          }}
        >
          <LoginIcon sx={{ fontSize: "2rem" }} />
        </ListItemIcon>
        <Typography variant="body2" sx={{ marginLeft: 1 }}>
          Log in
        </Typography>
      </ListItemButton>
    </Tooltip>
  );
}
