import React, { useState, useEffect } from "react";
import {
  Tooltip,
  ListItemIcon,
  ListItemButton,
  MenuItem,
  Menu,
  Typography,
} from "@mui/material";
import { Business as BusinessIcon } from "@mui/icons-material";

import { useAuthContext } from "../../context/AuthProvider";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { Colors } from "../../styles/themes";

export default function PartnershipsMenu({ matches }) {
  const { isAuth, partnershipList, activePartnership, setCurrentPartnerships } =
    useAuthContext();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(activePartnership);
  const navigate = useNavigate();
  const activeLinkStyle = {
    color: Colors.bottom,
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSwitchPartnership = async (partnership) => {
    await setCurrentPartnerships(partnership.partnershipID);
    setSelectedCompany(partnership);
    handleClose();
  };

  const navigateToAddPartnership = async () => {
    navigate("/partnership/add");
    handleClose();
  };

  // Add an effect to update the active partnership when it changes
  useEffect(() => {
    setSelectedCompany(activePartnership);
    if (activePartnership) {
      setAnchorEl(null);
    }
  }, [activePartnership]);

  if (isAuth) {
    return (
      <>
        <Tooltip title="Partnership">
          <ListItemButton
            sx={{
              justifyContent: "center",
            }}
            onClick={handleClick}
          >
            <ListItemIcon
              sx={{
                display: "flex",
                justifyContent: "center",
                color: Colors.primary,
                ":hover": {
                  color: Colors.bottom,
                },
                ...(window.location.pathname === "/partnership/add"
                  ? activeLinkStyle
                  : {}),
              }}
            >
              <BusinessIcon sx={{ fontSize: "2rem" }} />
            </ListItemIcon>
            <Typography
              variant="body2"
              sx={{
                marginLeft: 1,
                fontWeight: selectedCompany ? "bold" : "normal",
              }}
            >
              {selectedCompany ? selectedCompany.name : "Select Company"}
            </Typography>
          </ListItemButton>
        </Tooltip>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          {partnershipList.map((partnership) => (
            <MenuItem
              key={partnership.partnershipID}
              sx={{
                ":hover": {
                  color: Colors.secondary,
                },
                color:
                  partnership.partnershipID === selectedCompany?.partnershipID
                    ? Colors.secondary
                    : "initial", // Add this line
              }}
              onClick={() => handleSwitchPartnership(partnership)}
              selected={
                partnership.partnershipID === selectedCompany?.partnershipID
              }
            >
              <ListItemIcon
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  color: Colors.secondary,
                  ":hover": {
                    color: Colors.bottom,
                  },
                }}
              >
                <BusinessIcon />
              </ListItemIcon>
              {partnership.name}
            </MenuItem>
          ))}
          {partnershipList.every(
            (partnership) => partnership.vatNumber === "NOPARTNERSHIPDATA"
          ) ? (
            <MenuItem disabled>No Partnerships</MenuItem>
          ) : null}
          <MenuItem onClick={navigateToAddPartnership}>
            <AddIcon />
            Add Partnership
          </MenuItem>
        </Menu>
      </>
    );
  }
  return null;
}
