import axios from 'axios';
import { useCallback, useMemo } from 'react';

const baseUrl = `${process.env.REACT_APP_API_URL}/partnership`;

const usePartnership = () => {
  //Partnership options
  //create partnership
  const createPartnership = useCallback(async (partnershipDetails) => {
    await axios({
      method: 'POST',
      url: `${baseUrl}/create`,
      headers: {
        Authorization: localStorage.getItem('token'),
      },
      data: partnershipDetails,
    });
  }, []);

  //check vatnumber
  const checkVat = useCallback(async (companyVAT) => {
    const body = await axios({
      method: 'GET',
      url: `${baseUrl}/vat/${companyVAT}`,
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });
    return body.data;
  }, []);
  // //delete partnership
  // const deletePartnership = useCallback(async () => {
  //   await axios({
  //     method: 'DELETE',
  //     url: `${baseUrl}/delete`,
  //     headers: {
  //       Authorization: localStorage.getItem('token'),
  //     },
  //   });
  // });
  const getPartnershipUsersByPartnershipID = useCallback(async (partnershipID) => {
    const body = await axios({
      method: 'GET',
      url: `${baseUrl}/users/${partnershipID}`,
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });
    return body.data;
  }, []);

  const getPartnershipByID = useCallback(async (partnershipID) => {
    const body = await axios({
      method: 'GET',
      url: `${baseUrl}/${partnershipID}}`,
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });
    return body.data;
  }, []);

  const partnershipApi = useMemo(
    () => ({
      createPartnership,
      checkVat,
      getPartnershipByID,
      getPartnershipUsersByPartnershipID,
    }),
    [createPartnership, checkVat, getPartnershipByID, getPartnershipUsersByPartnershipID],
  );
  return partnershipApi;
};

export default usePartnership;
