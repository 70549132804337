import axios from 'axios';
import { useCallback, useMemo } from 'react';

const baseUrl = `${process.env.REACT_APP_API_URL}/premisesDocument`;

const usePremisesDocument = () => {
  //document options
  //create document
  const createDocument = useCallback(async (document) => {
    await axios({
      method: 'POST',
      url: `${baseUrl}/create`,
      headers: {
        Authorization: localStorage.getItem('token'),
        'Content-Type': 'multipart/form-data',
      },
      data: document,
    });
  }, []);

  //getDocuments by premisesId
  const getDocumentsByPremisesID = useCallback(async (documentID) => {
    console.log(documentID);
    const documents = await axios({
      method: 'GET',
      url: `${baseUrl}/${documentID}`,
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });
    return documents.data;
  }, []);

  const deleteDocumentByID = useCallback(async (documentID) => {
    await axios({
      method: 'DELETE',
      url: `${baseUrl}/delete/${documentID}`,
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });
  }, []);

  const premisesDocumentApi = useMemo(
    () => ({
      createDocument,
      getDocumentsByPremisesID,
      deleteDocumentByID,
    }),
    [createDocument, getDocumentsByPremisesID, deleteDocumentByID],
  );
  return premisesDocumentApi;
};

export default usePremisesDocument;
