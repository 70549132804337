import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import { Box, TextField, Button, Typography, Grid, Link, Avatar, Paper, Input, InputAdornment, IconButton, InputLabel } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

import isEmail from '../validation/IsMail';
import { useAuthContext } from '../../context/AuthProvider';
import { useSnackbarContext } from '../../context/ui/SnackbarProvider';
import { Colors } from '../../styles/themes';
import { Visibility, VisibilityOff } from '@mui/icons-material';

function backToLogin(userExists) {
  return userExists ? (
    <Button component={RouterLink} to='/login' variant='contained' value='/login'>
      Already have an account?
    </Button>
  ) : null;
}

export default function Register() {
  // state
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  // Password field
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // for form control
  const [required, setRequired] = useState(false);
  const [badEmail, setBadEmail] = useState(false);
  const [userExists, setUserExists] = useState(false);

  // context
  const { registerUser } = useAuthContext();
  const { handleSnackbar } = useSnackbarContext();

  // navigation
  const navigate = useNavigate();

  async function handleRegisterUser(user) {
    try {
      // await new Promise((resolve) => setTimeout(resolve, 500)); // SIMULATE NETWORK LATANCY
      await registerUser(user);
      navigate('/account');
      handleSnackbar({
        content: `Welcome ${user.email}`,
      });
    } catch (err) {
      if (err.response.status === 409) {
        setUserExists(true);
        throw new Error('This user already exists');
      } else {
        throw new Error('Something went wrong!');
      }
    }
  }

  const handleSubmit = async () => {
    setLoading(true);
    const user = {
      name,
      email,
      password,
    };
    if (!isEmail(user.email)) {
      handleSnackbar({
        content: 'Enter a valid e-mail',
        severity: 'error',
      });
      setBadEmail(true);
    } else if (user.email && user.password && user.name) {
      try {
        await handleRegisterUser(user);
      } catch (e) {
        handleSnackbar({
          content: 'Server might be currently be down!',
          severity: 'error',
        });
      }
    } else {
      setRequired(true);
      handleSnackbar({
        content: 'Enter all',
        severity: 'error',
      });
    }
    setLoading(false);
  };

  const handleChangeName = (event) => {
    if (required) {
      setRequired(false);
    }
    setName(event.target.value);
  };
  const handleChangeEmail = (event) => {
    if (required || badEmail) {
      setRequired(false);
      setBadEmail(false);
    }
    setEmail(event.target.value);
  };
  const handleChangePassword = (event) => {
    if (required) {
      setRequired(false);
    }
    setPassword(event.target.value);
  };

  return (
    <Grid
      container
      component='main'
      sx={{
        height: '80vh',
        width: '100%',
      }}
    >
      <Grid item xs={12} sm={8} md={5} sx={{ borderRadius: '25px 0 0 25px ' }} component={Paper} elevation={6} square>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <PersonAddIcon sx={{ mx: 'auto', color: Colors.secondary }} />
          </Avatar>
          <Typography component='h1' variant='h5'>
            Sign up
          </Typography>
          <Box component='form' sx={{ mt: 1 }}>
            <TextField error={required} id='standard-basic' label='Username' required type='username' autoComplete='current-username' onChange={handleChangeName} fullWidth />
            <TextField
              margin='normal'
              sx={{ mb: 2 }}
              required
              error={required || badEmail}
              fullWidth
              id='email'
              label='Email Address'
              name='email'
              autoComplete='email'
              autoFocus
              data-cy='testEmailRegister'
              onChange={handleChangeEmail}
            />
            <InputLabel htmlFor='standard-adornment-password'>Password</InputLabel>
            <Input
              id='outlined-adornment-password'
              type={showPassword ? 'text' : 'password'}
              fullWidth
              endAdornment={
                <InputAdornment position='end'>
                  <IconButton aria-label='toggle password visibility' onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge='end'>
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              onChange={handleChangePassword}
              label='Password'
            />
            <LoadingButton variant='contained' sx={{ mt: 3, mb: 2 }} fullwidth onClick={() => handleSubmit()} loading={loading}>
              Sign up
            </LoadingButton>
            <Grid container>
              <Grid item sx={{ ml: 'auto' }}>
                <Button variant='outlined' component={RouterLink} to='/login'>
                  Already have an account? Log in
                </Button>
              </Grid>
            </Grid>
            {backToLogin(userExists)}
            <Typography sx={{ mt: 5 }} variant='body2' color='text.secondary' align='center'>
              {'Copyright © '}
              <Link color='inherit' href='https://LexsIT'>
                LexsIT
              </Link>
              {" "}
              {new Date().getFullYear()}.
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage: 'url(https://picsum.photos/700)',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          borderRadius: ' 0 25px 25px 0 ',
          boxShadow: '10',
        }}
      />
    </Grid>
  );
}
