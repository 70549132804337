import { Card, Grid, Typography } from '@mui/material';
import JPaper from '../../styles/jPaper';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import AddHomeIcon from '@mui/icons-material/AddHome';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
export default function Features() {
  return (
    <Grid container spacing={4}>
      <Grid item sm={12} lg={4} md={6} justifyContent='center'>
        <JPaper elevation={6}>
          <Typography variant='h5' style={{ height: '32px' }}>
            <AddHomeIcon color='secondary' fontSize='large' />
          </Typography>
        </JPaper>
        <Card style={{ minWidth: '100%', minHeight: '80%', padding: '20px', fontSize: '1.2rem', textAlign: 'justify' }}>
          The "Add Properties" feature is a crucial component of a real estate portfolio management platform, designed to simplify the process of adding new properties to an investor's portfolio. This
          feature streamlines data entry, facilitates property analysis, and enables comprehensive tracking of real estate assets.
        </Card>
      </Grid>
      <Grid item sm={12} lg={4} md={6} justifyContent='center' textAlign='justify'>
        <JPaper elevation={6}>
          <Typography variant='h5' style={{ height: '32px' }}>
            <ManageSearchIcon color='secondary' fontSize='large' />
          </Typography>
        </JPaper>
        <Card style={{ minWidth: '100%', minHeight: '80%', padding: '20px', fontSize: '1.2rem' }}>
          The "Manage Payment" feature is an integral part of an online portfolio management platform that enables investors to handle various financial transactions related to their investment
          portfolios. This feature streamlines payment processes, provides secure payment options, and facilitates seamless interactions between investors and their portfolios.
        </Card>
      </Grid>
      <Grid item sm={12} lg={4} md={6} justifyContent='center' textAlign='justify'>
        <JPaper elevation={6}>
          <Typography variant='h5' style={{ height: '32px' }}>
            <PersonAddIcon color='secondary' fontSize='large' />
          </Typography>
        </JPaper>
        <Card style={{ minWidth: '100%', minHeight: '80%', padding: '20px', fontSize: '1.2rem' }}>
          The "Add Collaborators" feature is an essential aspect of modern portfolio management platforms or tools that allows investors to extend their investment reach by involving others in their
          investment journey. This feature is designed to facilitate collaboration, knowledge sharing, and joint decision-making among individuals or teams working towards a common investment goal.
        </Card>
      </Grid>
    </Grid>
  );
}
