import React, { useState } from 'react';
import { Box, Paper, Typography, Button, TextField, Grid, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { CloudUpload as CloudUploadIcon, Description as DescriptionIcon, EventNote as EventNoteIcon } from '@mui/icons-material';
import { useSnackbarContext } from '../../context/ui/SnackbarProvider';

const DocumentUploadComponent = ({ handleUploadDocument }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [documentType, setDocumentType] = useState('');
  const [expiryDate, setExpiryDate] = useState('');
  const { handleSnackbar } = useSnackbarContext();
  const documentTypes = ['AKTE', 'JAARLIJKSE AFREKENING', 'AFSCHRIFT', 'EIGENDOMSAKTE', 'UITTREKSEL', 'BODEMATTEST'];

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleUpload = () => { 
    if (selectedFile) {
      const allowedTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];

      if (allowedTypes.includes(selectedFile.type)) {
        // Perform the upload logic here, e.g., send the file to a server.
        // You can use the `selectedFile`, `documentType`, and `expiryDate` for this purpose.
        // Once the upload is complete, you can call `handleUploadDocument` with any relevant data.
        handleUploadDocument({
          name: selectedFile.name,
          document: selectedFile,
          documentType,
          expiryDate,
        });

        // Clear the input fields and selected file after upload
        setSelectedFile(null);
        setDocumentType('');
        setExpiryDate('');
      } else {
        // Display an error message or take other appropriate action for invalid file types
        handleSnackbar({
          content: 'Invalid file type. Please select a PDF or DOCX file.',
          severity: 'error',
          duration: 3000,
        });
        setSelectedFile(null);
      }
    }
  };

  return (
    <Paper elevation={3} sx={{ padding: '1rem', mt: 2, width: '70%' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography variant='h6' sx={{ mb: 2 }}>
          Upload Document
        </Typography>
        <label htmlFor='document-upload'>
          <input type='file' id='document-upload' style={{ display: 'none' }} accept='application/pdf' onChange={handleFileChange} />
          <Button variant='outlined' color='primary' component='span' startIcon={<CloudUploadIcon />} sx={{ mb: 2 }}>
            Select Document
          </Button>
        </label>
        {selectedFile && (
          <Typography variant='body1' sx={{ mt: 2 }}>
            <DescriptionIcon sx={{ mr: 1 }} />
            Selected file: {selectedFile.name}
          </Typography>
        )}
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} sx={{ display: 'flex', alignItems: 'stretch' }}>
            <FormControl fullWidth variant='outlined' required>
              <InputLabel htmlFor='property-type'>Document Type</InputLabel>
              <Select id='property-type' value={documentType} onChange={(e) => setDocumentType(e.target.value)} label='Document Type' displayEmpty variant='outlined'>
                {documentTypes.map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} sx={{ display: 'flex', alignItems: 'stretch' }}>
            <FormControl fullWidth variant='outlined'>
              <InputLabel shrink>Expiry Date</InputLabel>
              <TextField required variant='outlined' type='date' fullWidth value={expiryDate} onChange={(e) => setExpiryDate(e.target.value)} />
            </FormControl>
          </Grid>
        </Grid>

        <Button variant='contained' color='primary' disabled={!selectedFile || !expiryDate || !documentType} type='submit' onClick={handleUpload} sx={{ mt: 2 }}>
          <EventNoteIcon sx={{ mr: 1 }} />
          Upload Document
        </Button>
      </Box>
    </Paper>
  );
};

export default DocumentUploadComponent;
