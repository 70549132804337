import React, { Fragment, useCallback, useEffect, useState } from "react";
import {
  Typography,
  Box,
  Button,
  Stepper,
  Step,
  StepLabel,
  useMediaQuery,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSnackbarContext } from "../../../context/ui/SnackbarProvider";
import useProperty from "../../../api/property";
import { useAuthContext } from "../../../context/AuthProvider";
import { LoadingButton } from "@mui/lab";
import Header from "../foliocontrol/components/Header";
import DetailsStep from "./DetailsStep";
import PhotoStep from "./PhotoStep";
import ReviewStep from "./ReviewStep";

export default function AddProperty() {
  const propertyApi = useProperty();
  const { handleSnackbar } = useSnackbarContext();
  const { verify, activePartnership, partnershipList } = useAuthContext();
  const [loadingButton, setLoadingButton] = useState(false);
  const [propertyName, setPropertyName] = useState("");
  const [propertyType, setPropertyType] = useState("");
  const [street, setStreet] = useState("");
  const [streetNumber, setStreetNumber] = useState("");
  const [city, setCity] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [country, setCountry] = useState("");
  const [partnershipData, setPartnershipData] = useState([]);
  const [partnership, setPartnership] = useState("");

  const navigate = useNavigate();
  const [propertyImage, setPropertyImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const getPart = useCallback(async () => {
    try {
      setPartnershipData(partnershipList);
    } catch (error) {
      console.error(error);
    }
  }, [partnershipList]);

  useEffect(() => {
    async function fetchData() {
      await getPart();
    }

    fetchData();
  }, [activePartnership, getPart]);
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());

  const handleChange = (setter) => (event) => {
    setter(event.target.value);
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setPropertyImage(file);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoadingButton(true);
    const formattedProperty = {
      propertyName,
      propertyType,
      street,
      streetNumber,
      city,
      zipCode,
      country,
      propertyImage: propertyImage ? propertyImage : null,
      FK_partnershipID: activePartnership.partnershipID,
    };

    handleRegisterProperty(formattedProperty);
    navigate("/FolioControl/properties", { replace: true });
  };

  async function handleRegisterProperty(formattedProperty) {
    try {
      await propertyApi.createProperty(formattedProperty);
      await verify();
      handleSnackbar({
        content: "Successfully created property",
      });
      setLoadingButton(false);
    } catch (e) {
      setLoadingButton(false);
      handleSnackbar({
        content: e.message,
        severity: "error",
      });
    }
  }
  //STEPPER FUNCTIONS
  const steps = ["Details", "Photos & Documents", "Review"];
  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    if (activeStep === steps.length - 1) {
      handleReset();
      handleSubmit(new Event("submit"));
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  const matches = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  return (
    <Box m="0 20px">
      <Header
        title="ADD PROPERTY"
        subtitle="Add a property to your partnership's portfolio"
      />
      <Box sx={{ width: "100%" }}>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            if (isStepOptional(index)) {
              labelProps.optional = (
                <Typography variant="caption">Optional</Typography>
              );
            }
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>

        <Fragment>
          <form onSubmit={handleSubmit} style={{ width: "100%" }}>
            <Box
              sx={
                matches
                  ? { height: "54vh" }
                  : {
                      height: "60vh",
                    }
              }
            >
              {activeStep === 0 && (
                <DetailsStep
                  propertyName={propertyName}
                  setPropertyName={setPropertyName}
                  propertyType={propertyType}
                  setPropertyType={setPropertyType}
                  street={street}
                  setStreet={setStreet}
                  streetNumber={streetNumber}
                  setStreetNumber={setStreetNumber}
                  city={city}
                  setCity={setCity}
                  zipCode={zipCode}
                  setZipCode={setZipCode}
                  country={country}
                  setCountry={setCountry}
                  partnershipData={partnershipData}
                  partnership={partnership}
                  setPartnership={setPartnership}
                  activePartnership={activePartnership}
                  handleChange={handleChange}
                />
              )}
              {activeStep === 1 && (
                <PhotoStep
                  handleFileChange={handleFileChange}
                  propertyImage={propertyImage}
                  previewImage={previewImage}
                  setPreviewImage={setPreviewImage}
                />
              )}
              {activeStep === 2 && (
                <ReviewStep
                  propertyName={propertyName}
                  propertyType={propertyType}
                  street={street}
                  streetNumber={streetNumber}
                  city={city}
                  zipCode={zipCode}
                  country={country}
                  partnership={partnership}
                  propertyImage={propertyImage}
                  activePartnership={activePartnership}
                  previewImage={previewImage}
                />
              )}
            </Box>
            <LoadingButton
              variant="contained"
              sx={{ mt: "10px" }}
              type="submit"
              loading={loadingButton}
              disabled={
                !(
                  activeStep === steps.length - 1 &&
                  propertyName &&
                  propertyType &&
                  street &&
                  streetNumber &&
                  city &&
                  zipCode &&
                  country
                )
              }
              fullWidth
            >
              Add Property
            </LoadingButton>
          </form>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </Button>
            <Box sx={{ flex: "1 1 auto" }} />
            {isStepOptional(activeStep) && (
              <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                Skip
              </Button>
            )}

            <Button
              onClick={handleNext}
              disabled={
                !(
                  propertyName &&
                  propertyType &&
                  street &&
                  streetNumber &&
                  city &&
                  zipCode &&
                  country
                )
              }
            >
              {activeStep === steps.length - 1 ? "Finish" : "Next"}
            </Button>
          </Box>
        </Fragment>
      </Box>
    </Box>
  );
}
