import { Box, Container, IconButton, Tab, Tabs, Tooltip, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbarContext } from '../../../context/ui/SnackbarProvider';
import { useDialogContext } from '../../../context/ui/DialogProvider';
import usePremises from '../../../api/premises';
import PremisesDetails from './PremisesDetails';
import PremisesPhotos from './PremisesPhotos';
import PremisesDocuments from './PremisesDocuments';
import { ArrowBack as ArrowBackIcon, Delete as DeleteIcon, House as HouseIcon, PhotoLibrary as PhotoLibraryIcon, Description as DescriptionIcon } from '@mui/icons-material';
import { Link as RouterLink } from 'react-router-dom';

export default function PremisesPage() {
  const { premisesID } = useParams();
  const { handleSnackbar } = useSnackbarContext();
  const { handleDialog } = useDialogContext();
  const { getPremisesByID, deletePremises } = usePremises();
  const navigate = useNavigate();
  const [premises, setPremises] = useState({});
  const [activeTab, setActiveTab] = useState(0);
  useEffect(() => {
    async function fetchData() {
      try {
        const premisesData = await getPremisesByID(premisesID);
        setPremises(premisesData);
        console.log(premisesData);
      } catch (error) {
        console.error('Error fetching property:', error);
      }
    }

    fetchData();
  }, [premisesID]);

  const handleDeletePremises = async () => {
    try {
      await deletePremises(premisesID);
      handleSnackbar({
        content: 'Successfully deleted Premises',
      });
      navigate(`/portfolio/propertyPremises`);
    } catch (error) {
      console.error('Error deleting Premises:', error);
    }
  };

  const deletePropertyDialog = () => {
    handleDialog({
      title: 'Delete property',
      content: 'Confirmation',
      moreInfo: `Are you sure you want to delete ${premises?.premisesName}`,
      confirmText: 'DELETE PROPERTY',
      ifErrorText: `Failed to delete property`,
      ifSuccessText: 'Successfully deleted property!',
      onConfirm: handleDeletePremises,
    });
  };

  return (
    <Container sx={{ py: '2rem', mt: '50px' }}>
      <Box display='flex' alignItems='center' mb={3}>
        <IconButton component={RouterLink} color='primary' to={`/FolioControl/properties/premises/${premises.FK_propertyID}`}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant='h4' gutterBottom>
          {premises?.premisesName}
        </Typography>
        <Tooltip title='Delete Premises'>
          <IconButton onClick={deletePropertyDialog} sx={{ marginLeft: 'auto' }}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Box>

      <Tabs value={activeTab} onChange={(event, newValue) => setActiveTab(newValue)} centered>
        <Tab label='Property Details' icon={<HouseIcon />} />
        <Tab label='Photos' icon={<PhotoLibraryIcon />} />
        <Tab label='Documents' icon={<DescriptionIcon />} />
      </Tabs>

      {premises && (
        <>
          {activeTab === 0 && <PremisesDetails premises={premises} />}
          {activeTab === 1 && <PremisesPhotos premises={premises} />}
          {activeTab === 2 && <PremisesDocuments premises={premises} />}
        </>
      )}
    </Container>
  );
}
