import React, { useState } from 'react';
import { Box, Paper, Typography, Button } from '@mui/material';
import { CloudUpload as CloudUploadIcon, Image as ImageIcon } from '@mui/icons-material';
import { useSnackbarContext } from '../../context/ui/SnackbarProvider';

const PhotoUploadComponent = ({ handleUploadPhoto }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const { handleSnackbar } = useSnackbarContext();

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleUpload = () => {
    if (selectedFile) {
      const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg'];

      if (allowedTypes.includes(selectedFile.type)) {
        if (selectedFile.size < 5000000) {
          handleUploadPhoto(selectedFile);
          setSelectedFile(null);
        } else {
          // Display an error message or take other appropriate action for invalid file types
          handleSnackbar({
            content: 'Invalid file size. Please select a JPEG or PNG image below 5MB.',
            severity: 'error',
            duration: 3000,
          });
          setSelectedFile(null);
        }

        // Clear the input field and selected file after upload
      } else {
        // Display an error message or take other appropriate action for invalid file types
        handleSnackbar({
          content: 'Invalid file type. Please select a JPEG or PNG image.',
          severity: 'error',
          duration: 3000,
        });
        setSelectedFile(null);
      }
    }
  };

  return (
    <Paper elevation={3} sx={{ padding: '1rem', mt: 2, width: '70%' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography variant='h6' sx={{ mb: 2 }}>
          Upload Photo
        </Typography>
        <label htmlFor='photo-upload'>
          <input type='file' id='photo-upload' style={{ display: 'none' }} accept='image/jpeg, image/png, image/jpg' onChange={handleFileChange} />
          <Button variant='outlined' color='primary' component='span' startIcon={<CloudUploadIcon />} sx={{ mb: 2 }}>
            Select Photo
          </Button>
        </label>
        {selectedFile && (
          <Typography variant='body1' sx={{ mt: 2 }}>
            <ImageIcon sx={{ mr: 1 }} />
            Selected photo: {selectedFile.name}
          </Typography>
        )}

        <Button variant='contained' color='primary' disabled={!selectedFile} type='submit' onClick={handleUpload} sx={{ mt: 2 }}>
          <CloudUploadIcon sx={{ mr: 1 }} />
          Upload Photo
        </Button>
      </Box>
    </Paper>
  );
};

export default PhotoUploadComponent;
