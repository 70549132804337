import React from "react";

import SearchIcon from "@mui/icons-material/Search";
import { Link, useNavigate } from "react-router-dom";
import { ListItemText } from "@mui/material";
import {
  AppbarContainer,
  AppbarHeader,
  ListItemNavbar,
  MyList,
  Search,
  SearchIconWrapper,
  StyledInputBase,
} from "../../styles/navbar";
import Actions from "./Actions";
import { useAuthContext } from "../../context/AuthProvider";

export default function NavbarDesktop({ matches }) {
  const navigate = useNavigate();
  function HandleHomeClick() {
    navigate("/home");
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 30);
  }
  function HandleHowItWorksClick() {
    navigate("/home");
    setTimeout(() => {
      const howItWorks = document.getElementById("HowItWorks");

      const currentY = howItWorks.getBoundingClientRect().y;
      const newY = currentY - 300;

      window.scrollTo({
        top: newY,
        behavior: "smooth",
      });
    }, 30);
  }
  function HandleFeaturesClick() {
    navigate("/home");
    setTimeout(() => {
      const features = document.getElementById("Features");
      const currentY = features.getBoundingClientRect().y;
      const newY = currentY - 300;

      window.scrollTo({
        top: newY,
        behavior: "smooth",
      });
    }, 30);
  }
  function HandleAboutClick() {
    navigate("/home");
    setTimeout(() => {
      const aboutUsPaper = document.getElementById("AboutUsPaper");
      aboutUsPaper.scrollIntoView(
        {
          behavior: "smooth",
        },
        true
      );
    }, 30);
  }

  const { isAuth } = useAuthContext();

  if (isAuth) {
    return (
      <AppbarContainer>
        <ListItemNavbar
          sx={{ m: "0 auto 0 0" }}
          button
          onClick={() => HandleHomeClick()}
        >
          <AppbarHeader>FolioControl</AppbarHeader>
        </ListItemNavbar>
        <Search>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder="Search…"
            inputProps={{ "aria-label": "Search" }}
          />
        </Search>
        <Actions matches={matches} />
      </AppbarContainer>
    );
  } else {
    return (
      <AppbarContainer>
        <ListItemNavbar button onClick={() => HandleHomeClick()}>
          <AppbarHeader>FolioControl</AppbarHeader>
        </ListItemNavbar>

        <MyList type="row">
          <ListItemNavbar button onClick={() => HandleHowItWorksClick()}>
            <ListItemText primary="How it works" />
          </ListItemNavbar>
          <ListItemNavbar button onClick={() => HandleFeaturesClick()}>
            <ListItemText primary="Features" />
          </ListItemNavbar>
          <ListItemNavbar button onClick={() => HandleAboutClick()}>
            <ListItemText primary="About us" />
          </ListItemNavbar>
          <ListItemNavbar button component={Link} to="/assistance">
            <ListItemText primary="Assistance" />
          </ListItemNavbar>

          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              inputProps={{ "aria-label": "Search" }}
            />
          </Search>
        </MyList>
        <Actions matches={matches} />
      </AppbarContainer>
    );
  }
}
