import React, { createContext, useContext, useState, useMemo } from 'react';
import { Snackbar, Alert, Slide, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
export const SnackbarContext = createContext();
export const useSnackbarContext = () => useContext(SnackbarContext);

function Transition(props) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  return <Slide {...props} direction={matches ? 'left' : 'up'} />;
}

export function SnackbarProvider({ children }) {
  const [open, setOpen] = useState(false);
  const [content, setContent] = useState('');
  const [severity, setSeverity] = useState('success');
  const [autoHideDuration, setAutoHideDuration] = useState(3000);
  const [transition, setTransition] = useState(undefined);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  const value = useMemo(
    () => ({
      handleSnackbar: async (params) => {
        setContent(params.content);
        setSeverity(params.severity || 'success');
        setAutoHideDuration(params.duration || 3000);
        setOpen(true);
        setTransition(() => Transition);
      },
    }),
    [], // Empty dependencies, this ensures the handleSnackbar function is created only once.
  );

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <SnackbarContext.Provider value={value}>
      {children}
      <Snackbar
        sx={{ mt: { xs: '60px', md: '60px' } }}
        open={open}
        anchorOrigin={matches ? { vertical: 'top', horizontal: 'center' } : { vertical: 'bottom', horizontal: 'left' }}
        autoHideDuration={autoHideDuration}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
          {content}
        </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  );
}
