import { Box, Grid, Paper, Typography } from "@mui/material";

export default function ReviewStep({
  propertyName,
  propertyType,
  street,
  streetNumber,
  city,
  zipCode,
  country,
  partnership,
  propertyImage,
  activePartnership,
  previewImage,
}) {
  return (
    <Grid container spacing={3} justifyContent="center">
      <Grid item xs={12} md={8} margin={5}>
        <Paper
          sx={{
            minWidth: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "2rem",
          }}
        >
          <Typography variant="h5" gutterBottom>
            Review Your Property Information
          </Typography>

          <Typography variant="body1" paragraph>
            <strong>Property Name:</strong> {propertyName}
          </Typography>

          <Typography variant="body1" paragraph>
            <strong>Property Type:</strong> {propertyType}
          </Typography>

          <Typography variant="body1" paragraph>
            <strong>Address:</strong> {streetNumber} {street}, {city}, {zipCode}
            , {country}
          </Typography>

          <Typography variant="body1" paragraph>
            <strong>Partnership:</strong> {activePartnership.name}
          </Typography>

          {/* Display a preview image if available */}
          {previewImage && (
            <Box sx={{ mt: 2 }}>
              <img
                src={previewImage}
                alt="Selected Preview"
                style={{ maxWidth: "100%", maxHeight: "200px" }}
              />
            </Box>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
}
