import React, { useEffect, useState } from "react";
import {
  Typography,
  Box,
  TextField,
  Skeleton,
  Grid,
  Container,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import useProperty from "../../../api/property";
import { Save as SaveIcon } from "@mui/icons-material";
import { useSnackbarContext } from "../../../context/ui/SnackbarProvider";
import { PropertyTypes } from "../../../common";
export default function PropertyDetails({ property, setSave }) {
  const { handleSnackbar } = useSnackbarContext();
  const propertyApi = useProperty();
  const [changed, setChanged] = useState(false);
  const [oldValue, setOldValue] = useState({});
  const [loadingAddress, setLoadingAddress] = useState(true);
  const [saving, setSaving] = useState(false);

  const [propertyName, setPropertyName] = useState("");
  const [propertyType, setPropertyType] = useState("");
  const [propertyImg, setPropertyImg] = useState("");
  const [street, setStreet] = useState("");
  const [streetnumber, setStreetnumber] = useState("");
  const [city, setCity] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [country, setCountry] = useState("");
  const setAll = (property) => {
    setPropertyName(property.propertyName || "");
    setPropertyType(property.propertyType || "");
    setPropertyImg(property.propertyImg || "");
    setStreet(property.street || "");
    setStreetnumber(property.streetNumber || "");
    setCity(property.city || "");
    setZipCode(property.zipCode || "");
    setCountry(property.country || "");
  };

  useEffect(() => {
    setAll(property);
    setLoadingAddress(false);
    setOldValue(property);
  }, [property]);

  const handleChange = (setter, original) => (event) => {
    setChanged(true);
    setter(event.target.value);
    if (event.target.value === original) {
      setChanged(false);
    }
  };

  const saveChanges = async () => {
    setSaving(true);
    setSave(true);
    const changedValues = {
      propertyID: property.propertyID,
      propertyName,
      propertyType: propertyType,
      propertyImg,
      street,
      streetnumber,
      city,
      zipCode,
      country,
    };
    try {
      await propertyApi.updateProperty(changedValues);
      handleSnackbar({
        content: "Property information updated successfully",
        severity: "success",
      });
    } catch (e) {
      handleSnackbar({
        content: `Failed to update: ${e.message}`,
        severity: "error",
      });
    }

    setOldValue(changedValues);
    setChanged(false);
    setSaving(false);
  };

  return (
    <Container maxWidth="lg">
      <Paper
        sx={{
          marginTop: "20px",
          padding: "20px",
          margin: "20px auto",
          width: "100%",
          height: "100%",
        }}
      >
        <Typography variant="h5">Address information</Typography>

        <Box>
          {loadingAddress ? (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Skeleton sx={{ m: 1 }} />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Skeleton sx={{ m: 1 }} />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Skeleton sx={{ m: 1 }} />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Skeleton sx={{ m: 1 }} />
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {" "}
                <TextField
                  id="propertyName"
                  value={propertyName}
                  onChange={handleChange(
                    setPropertyName,
                    oldValue.propertyName
                  )}
                  label="Property Name"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                />
              </Grid>
            </Grid>
          )}
        </Box>
        <Box mt={2}>
          {loadingAddress ? (
            <>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <Skeleton sx={{ m: 1 }} />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Skeleton sx={{ m: 1 }} />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Skeleton sx={{ m: 1 }} />
                </Grid>
              </Grid>
            </>
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                {" "}
                <TextField
                  id="street"
                  value={street}
                  onChange={handleChange(setStreet, oldValue.street)}
                  label="Street"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                {" "}
                <TextField
                  id="streetnumber"
                  value={streetnumber}
                  onChange={handleChange(
                    setStreetnumber,
                    oldValue.streetnumber
                  )}
                  label="Street Number"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                {" "}
                <TextField
                  id="city"
                  value={city}
                  onChange={handleChange(setCity, oldValue.city)}
                  label="City"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                {" "}
                <TextField
                  id="zipCode"
                  value={zipCode}
                  onChange={handleChange(setZipCode, oldValue.zipCode)}
                  label="Zip Code"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                {" "}
                <TextField
                  id="country"
                  value={country}
                  onChange={handleChange(setCountry, oldValue.country)}
                  label="Country"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  required
                >
                  <InputLabel htmlFor="property-type">Property Type</InputLabel>
                  <Select
                    id="property-type"
                    value={propertyType}
                    onChange={handleChange(
                      setPropertyType,
                      oldValue.propertyType
                    )}
                    label="Property Type"
                    displayEmpty
                    variant="outlined"
                  >
                    {PropertyTypes.map((type) => (
                      <MenuItem key={type} value={type.type}>
                        {type.icon}
                        {type.type}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          )}
        </Box>
        <LoadingButton
          loading={saving}
          onClick={saveChanges}
          disabled={!changed}
          loadingPosition="start"
          startIcon={<SaveIcon />}
          variant="outlined"
          fullWidth
        >
          Save changes
        </LoadingButton>
      </Paper>
    </Container>
  );
}
